import React from 'react';
import styles from './AppLoader.module.scss';

const Dots = () => {
	return (
		<div>
			<div className={styles.dot} />
			<div className={styles.dot} />
			<div className={styles.dot} />
		</div>
	);
};

export default Dots;
