import React, {useState} from 'react';
import {Field, Formik,} from 'formik';
import { Form, Button, Col } from 'react-bootstrap';
import * as yup from 'yup';
import {Country} from "autodevelo-api-client-ts";
import CheckboxGroup from "../SearchForm/CheckboxGroup";
import CountryCheckbox from "./CountryCheckbox";

const requiredError = 'Toto pole je povinné.';

const schema = yup.object({
	countries: yup.string().required(requiredError),
});

// Checkbox input
const Checkbox = ({
      field: { name, value, onChange, onBlur },
      id,
      label,
      className,
      ...props
  }) => {
	return (
		<Col sm="6" md="4" xl="3" className="text-center">
			<CountryCheckbox
				id={id}
				title={props.title}
				name={name}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
			/>
		</Col>
	);
};


interface Props {
	saveFormData(values: any): void;
	submit(step: number): void;
	countries: Array<Country>;
	selectedCountries?: Array<number>;
}

export default function NextInfoForm(props: Props) {
	const [show, setShow] = useState(false);

	const nextStep = () => {
		props.submit(5);
	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={(values, actions) => {
			actions.setSubmitting(false);
			props.saveFormData(values);
			props.submit(5);
		}}
		initialValues={{
			countries: [],
			otherCountries: [],
			nextInfo: null,
		}}
	>
		{({
			handleSubmit,
			setFieldValue,
			setFieldTouched,
			values,
			touched,
			isSubmitting
		}) => {

			return (
			<Form noValidate onSubmit={handleSubmit}>
				<Form.Row>
					<Form.Row className="justify-content-left">
						<CheckboxGroup
							id="countries"
							label="Vyberte dálniční známky (v ceně) pro včasné nachystání:"
							value={values.countries}
							touched={touched.countries}
							onChange={setFieldValue }
							onBlur={setFieldTouched}
							align={'justify-content-left'}
							textAlign={'text-left'}
						>
							{props.countries.filter(country => country.isFavorite).map((country, key) => (
								<Field
									key={key}
									id={'' + country.id}
									name="countries"
									component={Checkbox}
									title={country.name}
									label={country.name}
								/>
							))}
						</CheckboxGroup>
					</Form.Row>

					{/*<div className={'ml--2 mt-3'}>*/}
					{/*	<a onClick={() => setShow(!show)} className={styles.showMore}>*/}
					{/*		{show && 'Skrýt '}*/}
					{/*		{!show && 'Zobrazit '}*/}
					{/*		ostatní země*/}
					{/*	</a>*/}
					{/*</div>*/}

					{/*{show &&*/}
					{/*	<Form.Row className="justify-content-center">*/}
					{/*		<CheckboxGroup*/}
					{/*			id="otherCountries"*/}
					{/*			label={null}*/}
					{/*			value={values.otherCountries}*/}
					{/*			touched={touched.otherCountries}*/}
					{/*			onChange={setFieldValue}*/}
					{/*			onBlur={setFieldTouched}*/}
					{/*			align={'justify-content-left'}*/}
					{/*		>*/}
					{/*			{props.countries.filter(country => !country.isFavorite).map((country, key) => (*/}
					{/*				<Field*/}
					{/*					key={key}*/}
					{/*					id={'' + country.id}*/}
					{/*					name="countries"*/}
					{/*					component={Checkbox}*/}
					{/*					title={country.name}*/}
					{/*					label={country.name}*/}
					{/*				/>*/}
					{/*			))}*/}
					{/*		</CheckboxGroup>*/}
					{/*	</Form.Row>*/}
					{/*}*/}


					<Form.Group as={Col} className="justify-content-right text-right mt-3">
						{/*<span>*/}
						{/*	<div onClick={nextStep} className="btn btn-outline-primary px-5">Přeskočit</div>*/}
						{/*</span>*/}
						<span className={'ml-1'}>
							<Button disabled={isSubmitting} type="submit">Pokračovat</Button>
						</span>
					</Form.Group>
				</Form.Row>
			</Form>
		)}}
	</Formik>
	);
}
