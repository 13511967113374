import React from 'react';
import styles from './ReferenceBlock.module.scss';

export default function ReferenceBlock (props) {
	return (
		<div className={styles.referenceBlock}>
			<p className="text-white">
				{props.text}
			</p>
			<div className={styles.referenceBlock__author}>
				{props.author}
			</div>
		</div>
	)
}

