import React, { Component } from 'react';
import MultiSelect from 'react-select';

interface IMultiSelectProps {
	options: any,
	setFieldValue: any,
	values: any
}
interface IMultiSelectState {
	selected: any
}

export default class MultiSelectWithFormik extends Component<IMultiSelectProps, IMultiSelectState> {
	constructor(props, context) {
		super(props, context);
		this.state = {
			selected: this.props.values["vehicleEquipment"]
		}
	}

	handleSelectChange = selected => {
		if (selected === null) {
			selected = [];
		}

		this.setState({selected});
		this.props.setFieldValue("vehicleEquipment", selected);
	}

	render() {
		const customStyles = {
			control: (provided, state) => ({
				...provided,
				display: 'flex',
				justifyContent: 'space-between',
				width: '100%',
				height: 'calc(1.5em + 1.5rem + 2px)',
				padding: '0 1.25rem',
				fontSize: '1rem',
				fontWeight: 400,
				lineHeight: 1.5,
				color: '#495057',
				border: '1px solid #0099cc',
				borderRadius: '1.56rem',
				overflow: 'auto',
        paddingRight: '45px',
				outline: 0,
				':hover': {
					border: '1px solid #0099cc'
				},
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 153, 204, 0.25)' : 'none'
			}),
			option: (provided) => ({
				...provided,
				textAlign: 'left',
			}),
      indicatorsContainer: () => ({
        display: 'none'
      })
		}
		return (
			<MultiSelect
				value={this.state.selected}
				onChange={this.handleSelectChange}
				options={this.props.options}
        placeholder=""
				isMulti
				isSearchable
				closeMenuOnSelect={false}
				styles={customStyles}
			/>
		)
	}
}
