import React from 'react';
import styles from './Logo.module.scss';
import LogoSVG from '../Icons/Logo';

export default function logo (props: {width?: number, height?: number}) {
	const h = props.height ? props.height : 80;

	return (
			<a href="/" className={styles.logo}>
				<LogoSVG width={props.width} height={h}/>
			</a>
	)
}
