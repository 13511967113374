export const ActionType = {
	TOGGLE_NAVIGATION: 'TOGGLE_NAVIGATION',
	SET_COUNTER: 'SET_COUNTER',
	SET_FORM: 'SET_FORM',
	SET_RESERVATION_FORM: 'SET_RESERVATION_FORM',
	SET_LOADING: 'SET_LOADING',
};

export function toggleNavigation() {
	return {
		type: ActionType.TOGGLE_NAVIGATION,
	};
}

export function setCounter(value: number, id: any) {
	return {
		type: ActionType.SET_COUNTER,
		id,
		value
	};
}

export function setFormData(data: object) {
	return {
		type: ActionType.SET_FORM,
		data
	};
}

export function setReservationFormData(data: object) {
	return {
		type: ActionType.SET_RESERVATION_FORM,
		data
	};
}

export function setLoading(isLoading: boolean) {
	return {
		type: ActionType.SET_LOADING,
		isLoading
	};
}
