import React from 'react';
import { connect } from 'react-redux';
import compose from '../../hoc/composition';
import { IAppState } from '../../store/reducer';
import { setCounter } from '../../store/actions';
import VehicleEquipmentItem from '../../components/VehicleEquipmentItem/VehicleEquipmentItem';
import {Car} from "autodevelo-api-client-ts";

interface IVehicleEquipmentListProps {
	itemValue: object,
	setCounter: (value: number) => void,
	content: Car
}

export default compose(
	connect(
		(state: IAppState) => ({ itemValue: state.reservationFormData['services'], formData: state.formData }),
		{ setCounter }
	)
)((props: IVehicleEquipmentListProps) => {


	const rentServices = Object.values(props.content.rentServices);

	return rentServices.map((rentService: any, key) => {
		return (
			<VehicleEquipmentItem
				key={key}
				service={rentService}
				itemValue={props.itemValue[rentService.id] ? props.itemValue[rentService.id].count : 0}
				onChange={props.setCounter}
			/>
		)
	});
});
