import React from 'react';
import withContent from '../../hoc/Content';
import WpContent from '../../hoc/loaders/Wp';
import { Container, Row, Col } from 'react-bootstrap';
import Heading from '../../components/Heading/Heading';
import SimpleText from '../../components/SimpleText/SimpleText';
import Card from '../../components/Card/Card';

export default withContent({ content: WpContent.ACF_HOW_IT_WORKS })(({ content: {
	hiwHeading,
	hiwSubheading,
	hiwArticle,
	hiwCards
}}) => {



	let cards = null;
	if (Array.isArray(hiwCards)) {
		hiwCards.map(({ icon: { id, url, alt }, title, text, info }) => (
			<Col key={id} lg={6} xl={4}>
				<Card
					icon={url}
					iconAlt={alt}
					title={title}
					text={text}
					info={info}
				/>
			</Col>
		));
	} else {
		console.error('HiwCards not found');
	}

	return (
		<section id="jak-to-funguje">
			<Container className="py-5">
				<Row className="text-center justify-content-center">
					<Col lg={7}>
						<Heading title={hiwHeading} subtitle={hiwSubheading} />
						<SimpleText content={hiwArticle} className={"text-light mt-4"}/>
					</Col>
				</Row>
				<Row className="mt-5 justify-content-center">
					{cards}
				</Row>
			</Container>
		</section>
	);
});
