import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import DetailTabs from '../../components/DetailTabs/DetailTabs';
import VehicleEquipmentList from '../VehicleEquipmentList/VehicleEquipmentList';
import ReservationCalendar from '../ReservationCalendar/ReservationCalendar';
import { Link } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router';
import {PriceHelper} from "../../utils/PriceHelper";
import {readHash, writeHash} from "../../store/hash";
import {Car, RentOfferedService} from "autodevelo-api-client-ts";
import {OfferedServices} from "../../components/OfferedServices/OfferedServices";

function DetailMainSection(props: RouteComponentProps & {content: any, vehicleEquipmentList?: any}) {
	const car: Car = props.content[0],
				confirmedReservations = props.content[1],
				unconfirmedReservations = props.content[2],
				rentKeyInformationPage = props.content[3],
				rentOfferedServices: Array<RentOfferedService> = props.content[4];


	let totalPrice = car.rentPrice.price;
	for (let i in props.vehicleEquipmentList) {
		let price = props.vehicleEquipmentList[i].priceDay * props.vehicleEquipmentList[i].count;
		totalPrice += price;
	}

	const reservationLink = '/rezervace#' + writeHash(readHash(), false);
	return (
		<section className="pb-5">
			<Container>
				<Row className="justify-content-end">
					<Col lg={4} xl={4}>
						<ReservationCalendar confirmedReservations={confirmedReservations} unconfirmedReservations={unconfirmedReservations}/>
					</Col>
					<Col lg={8} xl={8}>
						<DetailTabs
							car={car}
							rentKeyInformationPage={rentKeyInformationPage}
							rentOfferedServices={rentOfferedServices}
						/>

						<hr />
						<h2 className="h5 text-darkBlue mt-4"><strong>Extra výbava</strong></h2>

						{car.rentServices.length === 0 &&
							<Row>
								<Col xl={10}>
									<div className="text-light mt-1">K tomuto vozu není dostupná žádná extra výbava.</div>
								</Col>
							</Row>
						}

						{car.rentServices.length > 0 &&
							<Row className="justify-content-end">
								<Col xl={10}>
									<VehicleEquipmentList content={car}/>
								</Col>
							</Row>
						}


						<hr className="mt-5" />
					</Col>
					<Col sm={12} className="text-right mt-4">
						<div className="d-inline-block">
							<Link to="/">Nové hledání</Link>

							{car.isAvailalbeForRent() &&
								<>
									<div className="d-inline-block align-middle py-2 pl-5 pr-3 mx-4 bg-darkBlue text-white text-right">
										<div>Cena celkem:</div>
										<div><strong>{PriceHelper.formatPrice(totalPrice)}</strong></div>
									</div>
									<Link to={reservationLink} className="btn btn-primary">Rezervovat</Link>
								</>
							}

						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
}

export default withRouter(DetailMainSection);
