import styles from './DiscountCircle.module.scss';
import React from "react";

interface Props {
	percentageDiscount: number;
}

export const DiscountCircle = (props: Props) => (
	<div className={styles.discountCircle}>
		-{props.percentageDiscount} %
	</div>
);
