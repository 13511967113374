import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './VehicleListItem.module.scss';
import {Car, RentOfferedService, RentSettings} from 'autodevelo-api-client-ts';
import {readHash, writeHash} from '../../store/hash';
import { withRouter, RouteComponentProps } from 'react-router';
import {PriceHelper} from "../../utils/PriceHelper";
import VehicleImage from "../VehicleImage/VehicleImage";
import CarHelper from "../../utils/CarHelper";
import {DiscountCircle} from "../DiscountCircle/DiscountCircle";
import {OfferedServices} from "../OfferedServices/OfferedServices";

interface Props {
	car: Car;
	rentOfferedServices: Array<RentOfferedService>;
	rentSettings: RentSettings;
}

function VehicleListItem (props: Props & RouteComponentProps) {
	const handleLinkClick = (carId, path) => {
		let hashData = readHash();
		hashData['carId'] = carId;
		props.history.push('/' + path + '#' + writeHash(hashData, false));
	};

	const {car, rentOfferedServices, rentSettings} = props;

	let days = '0 dnů';

	if (car.rentPrice.days === 1 ) {days = car.rentPrice.days + ' den'}
	if (car.rentPrice.days >= 2 ) {days = car.rentPrice.days + ' dny'}
	if (car.rentPrice.days >= 5 ) {days = car.rentPrice.days + ' dní'}

	const percentageDiscount = car.rentPrice.calculatePercentageDiscount();

	return (
		<>
		<Row className={styles.border}>
			<Col md={6} lg={4} className="text-center">
				{(percentageDiscount && car.isAvailalbeForRent()) && <DiscountCircle percentageDiscount={percentageDiscount}/>}
				<VehicleImage car={car}/>
			</Col>
			<Col md={6} lg={4} className="text-center mt-4 mt-lg-0">
				<div className="d-inline-block text-left">
					<h2 className={styles.name}>{CarHelper.printTitle(car)}</h2>
					<ul className={styles.list}>
						<li>Rok výroby: <strong>{car.year}</strong></li>
						<li>Počet míst: <strong>{car.printSeats()} pasažérů / {car.printBeds()} spaní / {car.printTables()} stolování</strong></li>
						<li>Číslo vozu: <strong>{car.identification}</strong></li>
						<li>Převodovka: <strong>{CarHelper.printGearbox(car)}</strong></li>
						<li>Lokace vozu: <strong>{car.location ? car.location.name : '-'}</strong></li>
						<li>Klimatizace: <strong>{CarHelper.hasAirConditioning(car, rentSettings.settingExternalCarRental) ? 'Ano' : 'Ne'}</strong></li>
					</ul>
				</div>
			</Col>
			<Col lg={4} className="text-center mt-4 mt-lg-0">
				<div className={styles.priceHeadign}>

					{car.isAvailalbeForRent() &&
						<>Cena za {days} nájmu:</>
					}

					{!car.isAvailalbeForRent() &&
						<>Vůz není možné zapůjčit</>
					}

				</div>


				{car.isAvailalbeForRent() &&
					<div className={styles.price}>{PriceHelper.formatPrice(car.rentPrice.price)}</div>
				}

				{(car.isAvailalbeForRent() && car.rentPrice.priceDiscount > 0) &&
					<div className={styles.priceNoDiscount}>{PriceHelper.formatPrice(car.rentPrice.priceNoDiscount)}</div>
				}

				<div className="mt-4">
					<button onClick={() => handleLinkClick(car.id, 'detail-vozu')} className="btn btn-outline-primary mb-2 mb-xxl-0 mx-2">Detail vozu</button>
					{car.isAvailalbeForRent() &&
						<button onClick={() => handleLinkClick(car.id, 'rezervace')} className="btn btn-primary mx-2">Rezervovat</button>
					}
				</div>
			</Col>

			<Col sm={0} md={0} lg={6} xl={8}></Col>
			<Col sm={12} md={6} lg={6} xl={4}>
				<div className={'mt-sm-3 mt-lg-0'}></div>

				{car.allowOfferedServices && rentOfferedServices.length > 0 &&
					<OfferedServices offeredServices={rentOfferedServices} showTitle/>
				}
			</Col>

		</Row>
		</>
	);
}

export default withRouter(VehicleListItem);
