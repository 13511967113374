import React from 'react';
import { readHash } from '../../store/hash';
import withContent, { IWithContent } from '../../hoc/Content';
import ApiContent from '../../hoc/loaders/Api';
import compose from '../../hoc/composition';
import { connect } from 'react-redux';
import { IAppState } from '../../store/reducer';
import ReservationHero from '../ReservationHero/ReservationHero';
import ReservationTabs from '../ReservationTabs/ReservationTabs';
import {setFormData} from "../../store/actions";
import moment from 'moment';
import ErrorPage from "../ErrorPage/ErrorPage";
import {Car, Country, RentSettings} from "autodevelo-api-client-ts";
import DocumentTitle from "react-document-title";
import CarHelper from "../../utils/CarHelper";

interface IReservationPageProps {
	content: {
		car: Car,
		rentSettings: RentSettings,
		countries: Array<Country>,
	},
	formData: any;

}


function ReservationPage(props: IWithContent & IReservationPageProps) {

	let error = null;

	if (!props.content.car.isAvailalbeForRent()) {
		error = 'Vůz není možné zapůjčit.';
	} else if (!props.content.rentSettings.openingHours.isOpenInDayForRent(props.formData.startDate.toDate())) {
		error = 'V tento den je autopůjčovna zavřená a není možno si vůz zapůjčit.';
	} else if (!props.content.rentSettings.openingHours.isOpenInDayForRentReturn(props.formData.endDate.toDate())) {
		error = 'V tento den je autopůjčovna zavřená a není možno vůz vrátit.';
	}


	if (error) {
		return (
			<>
				<ErrorPage error={error}/>
			</>
		);
	}

	const title = CarHelper.printTitle(props.content.car);

	return (
		<DocumentTitle title={title + ' | Dovolená s Fordem'}>
			<>
				<ReservationHero car={props.content.car} settingExternalCarRental={props.content.rentSettings.settingExternalCarRental}/>
				<ReservationTabs content={props.content.car} countries={props.content.countries} rentSettings={props.content.rentSettings} formData={props.formData} />
			</>
		</DocumentTitle>
	);
}

export default compose(
	withContent({
		content: ApiContent({
			fetch: async (client, props) => {
				const hashData = readHash();

				props.dispatch(setFormData({
					startDate: moment(hashData['startDate']),
					endDate: moment(hashData['endDate']),
				}));

				return {
					car: await client.readCarDetail(hashData['carId'], new Date(hashData['startDate']), new Date(hashData['endDate'])),
					rentSettings: await client.readRentSettings(),
					countries: await client.getAllCountries(),
				};
			},
			cache: false
		})
	}),
	connect((state: IAppState) => ({formData: state.formData})),
)(ReservationPage);
