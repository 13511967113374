import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import './assets/scss/style.scss';
import Layout from './components/Layout/Layout';
import HomeHero from './containers/HomeHero/HomeHero';
import AboutSection from './containers/AboutSection/AboutSection';
import HowItWorksSection from './containers/HowItWorksSection/HowItWorksSection';
import YoutubeBannerSection from './containers/YoutubeBannerSection/YoutubeBannerSection';
import AdvantagesSection from './containers/AdvantagesSection/AdvantagesSection';
import ContactSection from './containers/ContactSection/ContactSection';
import ResultsHero from './containers/ResultsHero/ResultsHero';
import ContactFormSection from './containers/ContactFormSection/ContactFormSection';
import ReservationPage from './containers/ReservationPage/ReservationPage';
import VehicleDetailPage from './containers/VehicleDetailPage/VehicleDetailPage';
import ReservationPreviewPage from "./containers/ReservationPreviewPage/ReservationPage";
import ErrorPage from "./containers/ErrorPage/ErrorPage";
import {ResultsSectionWrapper} from "./containers/ResultsSection/ResultSectionWrapper";
import DocumentTitle from "react-document-title";

function App() {
  return (
    <Router>
      <Layout>
        <Route exact path="/" component={Home} />
        <Route exact path="/vysledky" component={Results} />
        <Route exact path="/detail-vozu" component={VehicleDetail} />
        <Route exact path="/rezervace" component={Reservation} />
        <Route exact path="/vytvorit-rezervaci" component={ReservationPreview} />
      </Layout>
    </Router>
  );
}


function Error() {
	return (
    <>
			<ErrorPage error={"Nastala neočekávaná chyba"}/>
    </>
	);
}



function Home() {
  return (
    <DocumentTitle title={'Dovolená s Fordem'}>
	    <>
		    <HomeHero />
		    <AboutSection />
		    <HowItWorksSection />
		    <YoutubeBannerSection />
		    <AdvantagesSection />
		    <ContactSection />
	    </>
    </DocumentTitle>
  );
}

function Results() {
  return (
	  <DocumentTitle title={'Vyhledávání | Dovolená s Fordem'}>
	    <>
	    <ResultsHero />
		    <ResultsSectionWrapper />
	    <ContactFormSection />
	    </>
	  </DocumentTitle>
  );
}

function VehicleDetail() {
  return (
    <VehicleDetailPage />
  );
}

function Reservation() {
  return (
    <ReservationPage />
  );
}

function ReservationPreview() {
	return (
		<ReservationPreviewPage />
	);
}

export default App;
