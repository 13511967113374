import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Heading from '../../components/Heading/Heading';
import VehicleListItem from '../../components/VehicleListItem/VehicleListItem';
import withContent from '../../hoc/Content';

const ResultsSection = ({ content, activePage, handlePageChange, itemsCountPerPage }) => (
	<section>
		<Container className="py-5">

			{content.carResult.totalCount === 0 &&
				<Row>
					<Col className="text-center mb-4">
						<Heading title={`Nebyl nalezen žádný vůz odpovícající vašim požadavkům`}
						         subtitle={'Prosím změňte požadavky na předchozí stránce'}/>
					</Col>
				</Row>
			}

			{content.carResult.totalCount > 0 && (
				<>
					<Row>
						<Col className="text-center mb-4">
							<Heading title={`Nalezeno ${content.carResult.totalCount} vozů`} subtitle={'Odpovídající Vašim požadavkům'} />
						</Col>
					</Row>

					{content.carResult.cars.map(car => <VehicleListItem
						key={car.id}
						car={car}
						rentOfferedServices={content.rentOfferedServices}
						rentSettings={content.rentSettings}
					/>)}
					<Row className="mt-5">
					<Col sm={12} className="text-center">
						<Pagination
							activePage={activePage}
							itemsCountPerPage={itemsCountPerPage}
							totalItemsCount={content.carResult.totalCount}
							pageRangeDisplayed={5}
							onChange={handlePageChange}
						/>
					</Col>
				</Row>
			</>)
			}
		</Container>
	</section>
);

export default withContent()(ResultsSection);
