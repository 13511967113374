import React from 'react';
import dompurify from 'dompurify';

// Add a hook to make noopener links open a new window
dompurify.addHook('afterSanitizeAttributes', (node) => {
  if ('rel' in node && node.getAttribute('rel') === 'noopener') {
    node.setAttribute('target', '_blank');
  }
});

export default function SimpleText (props: {content: any, className?: string}) {
	const content = props.content;
	const sanitizer = dompurify.sanitize;

	return <div className={props.className} dangerouslySetInnerHTML={{__html: sanitizer(content)}}></div>;
}