import React from 'react';
import styles from './YoutubeBanner.module.scss';
import YouTube from 'react-youtube';

export default function YoutubeBanner(props: {videoSrc: string, text: string}) {
	const {videoSrc, text} = props;
	const opts = {
		playerVars: {
			autoplay: 1,
			controls: 0,
			disablekb: 1,
			loop: 1,
			modestbranding: 1,
      playlist: videoSrc
		}
	};

	const _onReady = event => {
		event.target.mute();
	}
	return (
		<div className={styles.wrapper}>
			<div className={styles.textWrap}>
				<div className={styles.text}>
					{text}
				</div>
				<a className="btn btn-primary text-white mt-4" href="https://www.youtube.com">YouTube kanál</a>
			</div>
			<div className={styles.video}>
				<YouTube
					videoId={videoSrc}
					className={styles.iframe}
					opts={opts}
					onReady={_onReady}
      	/>
				<div className={styles.overlay} />
			</div>
		</div>
	);
}
