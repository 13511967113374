import React, { Component } from 'react';
import {Form, Row, Col} from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/cs';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../assets/scss/react-dates-overrides.scss';
import styles from './ReservationCalendar.module.scss';
import {DayPickerRangeController, isInclusivelyAfterDay} from 'react-dates';
import {RentReservation} from "autodevelo-api-client-ts";

interface IReservationCalendarState {
  startDate: any,
	endDate: any,
	focusedInput: any
}


interface IReservationCalendarProps {
  confirmedReservations: Array<RentReservation>;
  unconfirmedReservations: Array<RentReservation>;
}


export default class ReservationCalendar extends Component<IReservationCalendarProps, IReservationCalendarState> {
  constructor(props) {
    super(props);
    this.state ={
      startDate: null,
			endDate: null,
			focusedInput: null
    };
  }


  isDayBlocked = (a: moment.Moment) => {
    return this.isDateIn(a, this.props.confirmedReservations);
  };


  isDayHighlighted = (a: moment.Moment) => {
	  return this.isDateIn(a, this.props.unconfirmedReservations);
  };


  isDateIn = (date: moment.Moment, reservations: Array<RentReservation>) => {
	  let isBlocked = false;
	  reservations.map((reservation: RentReservation) => {
		  let dateStringFrom = reservation.rentFrom.split(' ')[0];
		  let dateStringTo = reservation.rentTo.split(' ')[0];

		  if (date.isBetween(moment(dateStringFrom), moment(dateStringTo)) || date.isSame(dateStringTo, 'day')) {
			  isBlocked = true;
      }
      return reservation;
	  });
	  return isBlocked;
  };


  render() {
    const navPrev = (
      <div className={styles.navPrev}>
        předchozí měsíce
      </div>
    )
    const navNext = (
      <div className={styles.navNext}>
        další měsíce
      </div>
    )
    return (
      <div>
        <Row>
          <Col>
          <div className={styles.header}>
            <div className={styles.title}>Obsazenost</div>
            <ul className={styles.list}>
              <li className={styles.free}>Volný</li>
              <li className={styles.reserved}>Rezervovaný</li>
              <li className={styles.taken}>Obsazený</li>
            </ul>
          </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <DayPickerRangeController
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                initialVisibleMonth={null} // PropTypes.func or null
                numberOfMonths={3}
                navPrev={navPrev}
                navNext={navNext}
                orientation="vertical"
                hideKeyboardShortcutsPanel={true}
                isOutsideRange={day => !isInclusivelyAfterDay(day, moment())}
                isDayBlocked={this.isDayBlocked}
                isDayHighlighted={this.isDayHighlighted}
                verticalHeight={880}
                noBorder={true}
              />
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}
