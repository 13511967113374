import React from 'react';
import {Form} from 'react-bootstrap';
import styles from './VehicleTypeSelect.module.scss';

export default function VehicleTypeSelect(
	props: {
		id?: string,
		imgSrc?: string,
		imgAlt?: string,
		title?: string,
		price?: number,
		persons?: number
		isValid?: boolean,
		onChange?: any,
		name?: string,
		defaultChecked?: any,
		value: any,
		onBlur: any,
		checked?: boolean
	}
	) {
	const label = (
		<div className={styles.label}>
			<img src={process.env.PUBLIC_URL + props.imgSrc} alt="vehicleType"/>
			<div className={styles.labelTitle}>{props.title}</div>

			{!! props.price &&
				<div className={styles.labelPrice}>Cena od {props.price} Kč</div>
			}

			{!!props.persons &&
				<div className={styles.labelPersons}>až {props.persons} osob</div>
			}
		</div>
	)
	return (
		<div className="mb-4">
			<Form.Check
				className="p-0 mx-3 custom-checkbox--top-centered"
				type="checkbox"
				id={props.id}
				custom
				label={label}
				name={props.name}
				onChange={props.onChange}
				checked={props.value}
				onBlur={props.onBlur}
			/>
		</div>
	);
}
