import {Col, Row} from "react-bootstrap";
import styles from "../../components/ReservationPreview/ReservationPreview.module.scss";
import ReservationGeneral from "../ReservationGeneral/ReservationGeneral";
import DateHelper from "../../utils/DateHelper";
import {RentHasService, RentReservation, SettingExternalCarRental} from "autodevelo-api-client-ts";
import VehicleEquipmentImage from "../../components/VehicleEquipmentImage/VehicleEquipmentImage";
import {PriceHelper} from "../../utils/PriceHelper";
import ReservationPdfButton from "../../components/ReservationPreview/ReservationPdfButton";
import BackendClient from "../../api/BackendClient";
import React from "react";

interface Props {
	reservation: RentReservation;
	settingExternalCarRental: SettingExternalCarRental;
	showCarInfo?: boolean;
	showDocumentsToDownload?: boolean;
}

export const ReservationDetails = (props: Props) => {
	const {reservation, showCarInfo, showDocumentsToDownload, settingExternalCarRental} = props;

	return (
		<>
			{showCarInfo &&
				<Row>
					<Col sm={12} >
						<div className={styles.tabHeader}>
							<div>Údaje o zapůjčeném voze</div>
						</div>
					</Col>
					<Col sm={12} className="px-0">
						<ReservationGeneral
							reservation={reservation}
							settingExternalCarRental={settingExternalCarRental}
						/>
					</Col>
				</Row>
			}
			<Row>
				<Col sm={12}>
					<div className={styles.tabHeader}>
						<div>Údaje o výpujčce</div>
					</div>
				</Col>
				<Col md={6} xl={3} className="my-4">
					<div>
						<span className="text-gray-500">Místo vyzvednutí vozu: </span>
						<span className="text-primary">{reservation.locationRent.name}</span>
					</div>
					<div>
						<span className="text-gray-500">Místo vrácení vozu: </span>
						<span className="text-primary">{reservation.locationReturn.name}</span>
					</div>
				</Col>
				<Col md={6} xl={3} className="my-4">
					<div>
						<span className="text-gray-500">Od: </span>
						<span className="text-primary">{DateHelper.formatMomentDateTime(reservation.getRentFromDate())}</span>
					</div>
					<div>
						<span className="text-gray-500">Do: </span>
						<span className="text-primary">{DateHelper.formatMomentDateTime(reservation.getRentToDate())}</span>
					</div>
				</Col>
				<Col sm={12} xl={3} className="my-4">
					<div>
						<span className="text-gray-500">Počet cestujících: </span>
						<span className="text-primary">{reservation.travelers}</span>
					</div>
					{!!reservation.destination &&
					<div>
						<span className="text-gray-500">Destinace: </span>
						<span className="text-primary">{reservation.destination.name}</span>
					</div>
					}
				</Col>
				<Col sm={12} xl={3} className="my-4">
					<div>
						<span className="text-gray-500">Bezplatné parkování Vašeho vozu v místě převzetí: </span>
						<span className="text-primary">{reservation.freeParking ? 'Ano' : 'Ne'}</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<div className={styles.tabHeader}>
						<div>Volitelné vybavení</div>
					</div>
				</Col>
				<Col sm={12} className="my-4">
					<Row>
						<Col className="d-flex align-items-center my-3">
						{reservation.services.length === 0 &&
							<div className={'text-gray-500'}>Nebylo vybráno žádné volitelné vybavení.</div>
						}

						{reservation.services.map((hasService: RentHasService) => {
							return (
								<Col md={6} xl={4} className="d-flex align-items-center my-3">
									<VehicleEquipmentImage equipmentId={hasService.service.id}/>
									<div className="ml-3">
										<div className="text-gray-500"><strong>{hasService.service.name}</strong></div>
										<div className="text-primary">{hasService.count} x {PriceHelper.formatPrice(hasService.service.priceDayTax)} / den</div>
										<div className="text-primary text-gray-500 small">{PriceHelper.formatPrice(hasService.service.priceDay)} bez DPH</div>
										{hasService.service.description &&
											<div className="text-primary text-gray-500 small mt-1">{hasService.service.description}</div>
										}
									</div>
								</Col>
							)
						})}
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<div className={styles.tabHeader}>
						<div>Osobní a fakturační údaje</div>
					</div>
				</Col>
				<Col className="my-4">
					<ul className="list-unstyled text-gray-500">
						{!!reservation.client.ic && <li>{reservation.client.company}</li>}
						{!reservation.client.ic && <li>{reservation.client.name} {reservation.client.surname}</li>}
						{!!reservation.client.ic && <li>{reservation.client.streetDelivery}</li>}
						{!reservation.client.ic && <li>{reservation.client.street} {reservation.client.number}</li>}
						{!!reservation.client.ic && <li>{reservation.client.zipDelivery} {reservation.client.cityDelivery}</li>}
						{!reservation.client.ic && <li>{reservation.client.zip} {reservation.client.city}</li>}
						<li>{reservation.client.email}</li>
						<li>{reservation.client.phone}</li>
					</ul>
				</Col>

				{!!reservation.client.ic &&
				<Col className="my-4">
					<ul className="list-unstyled text-gray-500">
						<li>{reservation.client.company}</li>
						<li>{reservation.client.street} {reservation.client.number}</li>
						<li>{reservation.client.zip} {reservation.client.city}</li>
					</ul>
				</Col>
				}
			</Row>
			<Row>
				<Col sm={12}>
					<div className={styles.tabHeader}>
						<div>Platba</div>
					</div>
				</Col>
				<Col sm={12} className="my-4">
					{!!reservation.payTypeDeposit &&
					<div>
						<span className="text-gray-500">Platba zálohy: </span>
						<span className="text-primary">{reservation.payTypeDeposit.name}</span>
					</div>
					}

					{!!reservation.payTypeBail &&
					<div>
						<span className="text-gray-500">Platba kauce: </span>
						<span className="text-primary">{reservation.payTypeBail.name}</span>
					</div>
					}

					{/*{!!reservation.payType &&*/}
					{/*<div>*/}
					{/*	<span className="text-gray-500">Platba nájmu: </span>*/}
					{/*	<span className="text-primary">{reservation.payType.name}</span>*/}
					{/*</div>*/}
					{/*}*/}
				</Col>
			</Row>
			{showDocumentsToDownload &&
				<Row>
					<Col sm={12}>
						<div className={styles.tabHeader}>
							<div>Doklady ke stažení</div>
						</div>
					</Col>
					<Col lg={2} className="my-4">
						<ReservationPdfButton url={BackendClient.getApiUrl() + '/download-toc-pdf?id=' + reservation.id}
						                      title={'Obchodní podmínky'}/>
					</Col>
					<Col lg={2} className="my-4">
						<ReservationPdfButton url={BackendClient.getApiUrl() + '/download-reserve-pdf?id=' + reservation.id}
						                      title={'Rezervace'}/>
					</Col>
					<Col lg={2} className="my-4">
						{reservation.invoices.length > 0 &&
						<ReservationPdfButton
							url={BackendClient.getApiUrl() + '/download-invoice-pdf?id=' + reservation.id + '&invoiceId=' + reservation.invoices[0].id}
							title={'Faktura'}/>
						}
					</Col>
				</Row>
			}
			</>
	);
};
