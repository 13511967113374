import {Col, Row} from "react-bootstrap";
import React from "react";

export const ReservationSuccess = () => (
	<Row className="py-5 my-lg-5 justify-content-center">
		<Col md={8} lg={6} xl={5} className="text-center">
			<h1 className="text-uppercase h2 text-darkBlue">Rezervace Vašeho vozu proběhla v pořádku</h1>
			<div className="text-gray-500 h5">Na uvedený e-mail Vám zasíláme rekapitulaci objednávky.</div>
		</Col>
	</Row>
);
