import React from 'react';
import styles from './Card.module.scss';
import SimpleText from '../SimpleText/SimpleText';

export default function Card (props: {
	icon?: string,
	title?: string,
	text?: string,
	info?: string,
	iconAlt?: string,
	isCentered?: boolean
}) {
		const {icon, title, text, info, iconAlt, isCentered} = props;
		const centered = isCentered ? styles.cardCentered : styles.card
		return (
			<div className={centered}>
				<div className={styles.cardHeader}>
					<img src={icon} alt={iconAlt} width={96} height={96}/>
					<h2 className={styles.cardTitle}>{title}</h2>
				</div>
				<div className={styles.cardContent}>
					<SimpleText content={text} className="text-light"/>
					<SimpleText content={info} className="text-darkBlue font-bold"/>
				</div>
			</div>
		)
}