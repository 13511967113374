import React from 'react';
import styles from './OrderSummary.module.scss';
import { connect } from 'react-redux';
import { IAppState } from '../../store/reducer';
import {Car} from "autodevelo-api-client-ts";
import {PriceHelper} from "../../utils/PriceHelper";
import {ReservationDataHelper} from "../../utils/ReservationDataHelper";
// import {number} from "prop-types";

interface IOrderSummaryProps {
	content: Car;
	formData: any;
	reservationFormData: any;
}

function OrderSummary(props: IOrderSummaryProps) {
	let nuberOfDays = null;

	if (!!props.reservationFormData && !!props.reservationFormData.rentFrom && !!props.reservationFormData.rentTo) {
		nuberOfDays = ReservationDataHelper.rentDaysDiff(props.reservationFormData);
	} else {
		nuberOfDays = ReservationDataHelper.daysDiff(props.formData.startDate, props.formData.endDate);
	}


	let equipmentPrice = 0;
	let equipmentPriceTax = 0;
	for (let key in props.reservationFormData.services) {
		let service = props.reservationFormData.services[key];
		equipmentPrice += service.count * service.priceDay * nuberOfDays;
		equipmentPriceTax += service.count * service.priceDayTax * nuberOfDays;
	}

	let totalPrice = equipmentPrice + props.content.rentPrice.priceNoDiscount - props.content.rentPrice.priceDiscount;
	let totalPriceTax = equipmentPriceTax + props.content.rentPrice.priceNoDiscountTax - props.content.rentPrice.priceDiscountTax;

	return (
		<div className={styles.summaryWrapper}>
			<div className="pl-sm-4 pl-md-5 pl-lg-3 pl-xl-5">
				<h2 className="text-white text-uppercase h5"><strong>Shrnutí objednávky</strong></h2>

				<div className="d-sm-flex">
					<div className="text-light mr-sm-2">Cena vozu:</div>
					<div className="text-primary">
						<div><strong>{PriceHelper.formatPrice(props.content.rentPrice.priceNoDiscount)}</strong></div>
						<div className={styles.smallText}>{PriceHelper.formatPrice(props.content.rentPrice.priceNoDiscountTax)} vč. DPH</div>
					</div>
				</div>

				<div className="d-sm-flex">
					<div className="text-light mr-sm-2">Cena příslušenství:</div>
					<div className="text-primary">
						<div><strong>{PriceHelper.formatPrice(equipmentPrice)}</strong></div>
						<div className={styles.smallText}>{PriceHelper.formatPrice(equipmentPriceTax)} | vč. DPH</div>
					</div>
				</div>

			</div>
			<div>
				<div className="text-light my-3 mt-sm-0 pr-sm-4 pr-md-5 pr-lg-3 pr-xl-5">
					<strong>od</strong>
					<strong className="text-primary"> {props.formData.startDate.format('DD. MM. YYYY')} </strong>
					<strong>do</strong>
					<strong className="text-primary"> {props.formData.endDate.format('DD. MM. YYYY')} </strong>
					<span className="text-primary"> - {nuberOfDays} dní</span>
				</div>

				{!!props.content.rentPrice.priceDiscount &&
					<div className="d-sm-flex">
						<div className="text-light mr-sm-2">Sleva:</div>
						<div className="text-primary">
							<div><strong>- {PriceHelper.formatPrice(props.content.rentPrice.priceDiscount)}</strong></div>
							<div className={styles.smallText}>- {PriceHelper.formatPrice(props.content.rentPrice.priceDiscountTax)} | vč. DPH</div>
						</div>
					</div>
				}

				<div className="bg-gray-200 d-flex py-2 justify-content-center mt-3">
					<h2 className="text-darkBlue text-uppercase h5"><strong>Celková cena:</strong></h2>
					<div className="text-primary ml-3">
						<div><strong>{PriceHelper.formatPrice(totalPrice)}</strong></div>
						<div className={styles.smallText}>{PriceHelper.formatPrice(totalPriceTax)} vč. DPH</div>
					</div>
				</div>

				{!! props.content.rentPrice.priceDeposit &&
					<div className="bg-gray-200 d-flex py-2 justify-content-center mt-3">
						<h2 className="text-darkBlue text-uppercase h5"><strong>Záloha:</strong></h2>
						<div className="text-primary ml-3">
							<div><strong>{PriceHelper.formatPrice(props.content.rentPrice.priceDeposit)} vč. DPH </strong></div>
						</div>
					</div>
				}

				{!! props.content.rentPrice.priceBail &&
					<div className="bg-gray-200 d-flex py-2 justify-content-center mt-3">
						<h2 className="text-darkBlue text-uppercase h5"><strong>Kauce:</strong></h2>
						<div className="text-primary ml-3">
							<div><strong>{PriceHelper.formatPrice(props.content.rentPrice.priceBail)} vč. DPH </strong></div>
						</div>
					</div>
				}


			</div>
		</div>
	);
}

export default connect((state: IAppState) => ({formData: state.formData, reservationFormData: Object.assign({}, state.reservationFormData)}))(OrderSummary)
