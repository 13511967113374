import React, { Component } from 'react';
import {RentReservation, SettingExternalCarRental} from "autodevelo-api-client-ts";
import {Container} from 'react-bootstrap';
import {ReservationSuccess} from "../../containers/ReservationSuccess/ReservationSuccess";
import {ReservationDetails} from "../../containers/ReservationDetails/ReservationDetails";


interface ReservationPreviewProps {
	reservation: RentReservation;
	settingExternalCarRental: SettingExternalCarRental;
}


export default class ReservationPreview extends Component<ReservationPreviewProps, {}> {
  render() {
    const {reservation, settingExternalCarRental} = this.props;
		return (
			<Container>
				<ReservationSuccess/>
				<ReservationDetails
					reservation={reservation}
					showCarInfo
					showDocumentsToDownload
					settingExternalCarRental={settingExternalCarRental}
				/>
			</Container>
		)
  }
}
