import React from "react";
import {RentOfferedService} from "autodevelo-api-client-ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";

import styles from "./OfferedServices.module.scss";
import {Col, Row} from "react-bootstrap";

library.add(faCheck);
library.add(faInfoCircle);

interface Props {
	offeredServices: Array<RentOfferedService>;
	showTitle: boolean;
}

export const OfferedServices = (props: Props) =>  {
	const {showTitle} = props;

	return (
		<div className={styles.offeredServicesWrap}>
			{showTitle &&
				<div className={styles.title}>Zdarma Vám nabízíme</div>
			}

			<Row className={'row mt-2'}>
				{props.offeredServices.map((service) => {
					return (
						<Col sm={6}>
							<FontAwesomeIcon icon="check" className={'text-success'} />
							<span className={'ml-2'}>
								{service.name}
							</span>
							{service.description &&
								<a className={'ml-2'} title={service.description} data-toggle="tooltip">
									<FontAwesomeIcon icon="info-circle" size={'sm'} className={'text-success'}/>
								</a>
							}
						</Col>
					);
				})}
			</Row>
		</div>
	)
};
