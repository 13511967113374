import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import CameraSVG from '../Icons/CameraIcon';
import styles from './VehiclePreview.module.scss';
import VehicleImage from "../VehicleImage/VehicleImage";
import {Car} from "autodevelo-api-client-ts";
import {resolveCarImages} from "../../metadata";


interface IVehiclePreviewState {
	photoIndex: number,
	isOpen: boolean
}

export default class VehiclePreview extends Component<{car: Car}, IVehiclePreviewState> {
  images: Array<string>;

  constructor(props) {
    super(props);

    this.images = resolveCarImages(props.car);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
		const { photoIndex, isOpen } = this.state;

    return (
      <div>
        <div className={styles.preview}  onClick={() => this.setState({ isOpen: true })} >
			<VehicleImage car={this.props.car}/>
			<div className={styles.counter}>
				<CameraSVG width={22} height={18} className="mb-1" />
				<span className="text-primary ml-2 align-middle"><strong>{this.images.length}</strong></span>
			</div>
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={this.images[photoIndex]}
            nextSrc={this.images[(photoIndex + 1) % this.images.length]}
            prevSrc={this.images[(photoIndex + this.images.length - 1) % this.images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + this.images.length - 1) % this.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}
