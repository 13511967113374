import * as moment from "moment";

export default class DateHelper {

	public static formatDate(value: Date): string {
		let month = String(value.getMonth());
		let day = String(value.getDate());

		if (month.length === 1) {
			month = "0" + month;
		}

		if (day.length === 1) {
			day = "0" + day;
		}

		return day + '. ' + month + '. ' + value.getFullYear();
	}


	public static formatDateTime(value: Date): string {
		let month = String(value.getMonth());
		let day = String(value.getDate());
		let hours = String(value.getHours());
		let minutes = String(value.getMinutes());

		if (month.length === 1) {
			month = "0" + month;
		}

		if (day.length === 1) {
			day = "0" + day;
		}

		if (hours.length === 1) {
			hours = "0" + hours;
		}

		if (minutes.length === 1) {
			minutes = "0" + minutes;
		}

		return day + '. ' + month + '. ' + value.getFullYear() + ' ' + hours + ':' + minutes;
	}


	public static formatMomentDate(value: moment.Moment) {
		return value.format('DD. MM. YYYY');
	}

	public static formatMomentDateTime(value: moment.Moment) {
		return value.format('DD. MM. YYYY h:mm');
	}


}
