import React from 'react';
import withContent from '../../hoc/Content';
import WpContent from '../../hoc/loaders/Wp';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './ResultsHero.module.scss';
import { Link } from 'react-router-dom';

export default withContent({ content: WpContent.ACF_HOME_HERO })(() => {
	return (
	<section className={styles.bg}>
		<Container className="py-5">
			<Row className="justify-content-center">
				<Col md={9} xl={6} className="text-center">
					<Link to="/" className="btn btn-primary">Nové hledání</Link>
				</Col>
			</Row>
		</Container>
	</section>
	);
});
