import React from 'react';
import styles from './VehicleParameters.module.scss';
import {Car, SettingExternalCarRental} from "autodevelo-api-client-ts";
import CarHelper from "../../utils/CarHelper";

interface IVehicleParametersProps {
	car: Car;
	settingExternalCarRental: SettingExternalCarRental;
}

export default function VehicleParameters (props: IVehicleParametersProps) {
	const {car, settingExternalCarRental} = props;

	// Variables for VehicleParametres card
	let carYear = 'neuvedeno';
	if (car.variant !== undefined) { carYear = car.variant.modelYear.name };
	if (car.modelYear !== undefined) { carYear = String(car.modelYear.name) };
	if (car.year !== undefined) { carYear = String(car.year) };

	let passangersNum = '-';
	if (car.seats !== undefined) { passangersNum = String(car.seats) };

	let sleepNum = '-';
	if (car.beds !== undefined) { sleepNum = String(car.beds) };

	let tableNum = '-';
	if (car.tables !== undefined) { tableNum = String(car.tables) };

	let identification = '-';
	if (car.identification !== undefined) { identification = car.identification };

	let location = 'neuvedeno';
	if (car.location !== undefined) { location = car.location.name };

	let engine = 'neuvedeno';
	if (car.variant !== undefined && car.variant.engine !== undefined) { engine = car.variant.engine.name } else { engine = car.printCarEngine('neuvedeno'); }

	return (
		<div className={styles.bg}>
			<h2 className={styles.name}>{CarHelper.printTitle(car)}</h2>
			<ul className={styles.list}>
				<li>Rok výroby: <strong>{carYear}</strong></li>
				<li>Počet míst: <strong>{passangersNum} pasažérů / {sleepNum} spaní / {tableNum} stolování</strong></li>
				<li>Číslo vozu: <strong>{identification}</strong></li>
			</ul>
			<ul className={[styles.list, styles.margin].join(' ')}>
				<li>Převodovka: <strong>{CarHelper.printGearbox(car)}</strong></li>
				<li>Motor: <strong>{engine}</strong></li>
				<li>Lokace vozu: <strong>{location}</strong></li>
				<li>Klimatizace: <strong>{CarHelper.hasAirConditioning(car, settingExternalCarRental) ? 'Ano' : 'Ne'}</strong></li>
			</ul>
		</div>
	);
}
