import React, { PureComponent } from 'react';

interface IContentLoaderSuspenseProps {
	WrappedComponent: new() => React.Component<any, any>
}

const activeSuspenses = [];
let activeLoaderCount = 0;
let totalLoaderCount = 0;

export class ContentLoaderSuspense extends PureComponent<IContentLoaderSuspenseProps, {}> {
	componentDidMount() {
		activeSuspenses.push(this);
	}

	componentWillUnmount() {
		const i = activeSuspenses.indexOf(this);
		if (i !== -1) {
			activeSuspenses.splice(i, 1);
		}
	}

	render() {
		const { WrappedComponent, ...forward } = this.props;
		return <WrappedComponent
			{...forward}
			isLoading={activeLoaderCount !== 0}
			progress={totalLoaderCount > 0 ? activeLoaderCount / totalLoaderCount : 1} />;
	}

	static triggerUpdate() {
		activeSuspenses.forEach(suspense => suspense.forceUpdate());
	}

	static onLoadStart() {
		++activeLoaderCount;
		++totalLoaderCount;
		ContentLoaderSuspense.triggerUpdate();
	}

	static onLoadComplete() {
		if (--activeLoaderCount === 0) {
			totalLoaderCount = 0;
		}
		ContentLoaderSuspense.triggerUpdate();
	}
}

export default function withProgress(Component) {
	return props => <ContentLoaderSuspense {...props}  WrappedComponent={Component} />;
}
