import {Button, Col, Row} from "react-bootstrap";
import React from "react";
import {
	Car,
	Client,
	Country,
	InvoicePayType,
	RentReservation,
	SettingExternalCarRental
} from "autodevelo-api-client-ts";
import {IReservationFormData} from "../../store/reducer";
import {ReservationDetails} from "../ReservationDetails/ReservationDetails";
import {paymentTypes} from "../PayForm/PayForm";

interface Props {
	car: Car;
	reservationData: IReservationFormData;
	settingExternalCarRental: SettingExternalCarRental;
	countries: Array<Country>;
	submit(): void;
}

export const ReservationSummary = (props: Props) => {
	const {countries, car, reservationData, settingExternalCarRental} = props;

	return (
		<>
			<ReservationDetails
				reservation={getReservationDataForPreview(reservationData, car, countries)}
				settingExternalCarRental={settingExternalCarRental}
			/>
			<Row>
				<Col sm="12" className="text-right">
					<Button
						onClick={props.submit}
						className="mt-4 mx-auto"
						type="submit"
					>
						Odeslat rezervaci
					</Button>
				</Col>
			</Row>
		</>
	);
};


const getReservationDataForPreview = (reservationData: IReservationFormData, car: Car, countries: Array<Country>): RentReservation => {
	const client = new Client({
		company: !!reservationData.clientIc ? reservationData.clientBillingCompany : null,
		name: reservationData.clientFirstname,
		surname: reservationData.clientSurname,
		street: !!reservationData.clientIc ? reservationData.clientBillingStreet : reservationData.clientStreet,
		number: !!reservationData.clientIc ? reservationData.clientBillingNumber : reservationData.houseNumber,
		zip: !!reservationData.clientIc ? reservationData.clientBillingZip : reservationData.clientZip,
		city: !!reservationData.clientIc ? reservationData.clientBillingCity : reservationData.clientCity,
		email: reservationData.clientEmail,
		phone: reservationData.clientPhone,
		ic: reservationData.clientIc ? reservationData.clientIc : null,
		nameDelivery: !!reservationData.clientIc ? (reservationData.clientFirstname + ' ' + reservationData.clientSurname) : null,
		streetDelivery: !!reservationData.clientIc ? reservationData.clientStreet + ' ' + reservationData.houseNumber : null,
		cityDelivery: !!reservationData.clientIc ? reservationData.clientCity : null,
		zipDelivery: !!reservationData.clientIc ? reservationData.clientZip : null,
	});

	let services = [];
	for (let i in reservationData.services) {
		const serviceData = reservationData.services[i];
		services.push({
			count: serviceData.count,
			service: {
				id: serviceData.idService,
				...serviceData
			},
		});
	}

	const destinationCountry = countries.find(c => c.id == reservationData.destinationCountryId);

	return new RentReservation({
		locationRent: {
			name: car.location.name,
		},
		locationReturn: {
			name: car.location.name,
		},
		destination: {
			name: destinationCountry ? destinationCountry.name : null,
		},
		rentFrom: reservationData.rentFrom,
		rentTo: reservationData.rentTo,
		travelers: reservationData.travelersCount,
		freeParking: reservationData.freeParking,
		services: services,
		car: car,
		client: client,
		invoices: [],
		payType: reservationData.payTypeId ? new InvoicePayType({
			name: paymentTypes[Number(reservationData.payTypeId)],
		}) : null,
		payTypeDeposit: reservationData.payTypeDepositId ? new InvoicePayType({
			name: paymentTypes[Number(reservationData.payTypeDepositId)],
		}) : null,
		payTypeBail: reservationData.payTypeBailId ? new InvoicePayType({
			name: paymentTypes[Number(reservationData.payTypeBailId)],
		}) : null,
	});
};
