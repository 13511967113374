import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import DocumentTitle from "react-document-title";
interface IErrorPageProps {
	error: string;
}

export default function ErrorPage(props: IErrorPageProps) {
	return (
		<DocumentTitle title={'Chyba | Dovolená s Fordem'}>
			<Container className="py-lg-5">
				<Row className="py-5">
					<Col sm={12} className="text-center py-5">
						<div className="text-danger">
							<h1 className="display-3">Nastala chyba</h1>
							<div>{props.error}</div>
						</div>
					</Col>
				</Row>
			</Container>
		</DocumentTitle>
	);
}
