import React from 'react';

interface IReservationPdfButtonProps {
	url: string,
	title: string
}


export default function ReservationPdfButton (props: IReservationPdfButtonProps) {
	return (
		<div className="d-flex flex-column align-items-center">
			<img width="60px" height="auto" src="/img/pdf-icon.svg" alt="pdf icon"/>
			<a href={props.url} target={'_blank'} className="text-center mt-3">
				{props.title}
			</a>
		</div>
	);
}
