import React, { Component } from 'react';

interface IVehicleEquipmentImageProps {
	equipmentId: number,
}

interface IVehicleEquipmentImageState {
	iconSrc: string;
}

export default class VehicleEquipmentImage extends Component<IVehicleEquipmentImageProps, IVehicleEquipmentImageState> {

	constructor(props) {
		super(props);
		this.state = {
			iconSrc: process.env.PUBLIC_URL + '/img/equipment/' + props.equipmentId + '.png',
		};
	}


	onLoadImgError = () => {
		this.setState({
			iconSrc: process.env.PUBLIC_URL + '/img/equipment/empty.png',
		});
	};


	render = () => {
		return (
			<img className="img-fluid" src={this.state.iconSrc} alt="vybava" onError={this.onLoadImgError}  />
		);
	};

}
