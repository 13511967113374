import React, { Component } from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import {Car, EquipmentValue, RentOfferedService} from "autodevelo-api-client-ts";
import {OfferedServices} from "../OfferedServices/OfferedServices";

interface IDetailTabsState {
	key: string
}

interface Props {
	car: Car;
	rentKeyInformationPage: any;
	rentOfferedServices: Array<RentOfferedService>;
}

export default class DetailTabs extends Component<Props, IDetailTabsState> {
  constructor(props, context) {
    super(props, context);
    const {car, rentOfferedServices} = props;

    this.state = {
      key: car.allowOfferedServices && rentOfferedServices.length > 0 ? 'offeredServices' : 'equipment',
    };
  }


  render() {
	const {car, rentOfferedServices} = this.props;

    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={this.state.key}
		onSelect={key => this.setState({ key })}
      >
		  {car.allowOfferedServices && rentOfferedServices.length > 0 &&
			  <Tab eventKey="offeredServices" title="Zdarma nabízíme">
				  <OfferedServices offeredServices={rentOfferedServices} showTitle={false}/>
			  </Tab>
		  }

        <Tab eventKey="equipment" title="Vybavení">

			{car.equipmentValues.length === 0 &&
    	        <div className="text-light">Vybavení tohoto vozidla není známé.</div>
	        }


	        {car.equipmentValues.length > 0 &&
                <div className="text-left my-5 list-unstyled list-unstyled--custom text-light">
                    <ul>
                        {car.equipmentValues.map((equipmentValue: EquipmentValue, index: number) => {
                          return (<li key={index}>{equipmentValue.printtEquipment(equipmentValue)}</li>);
                        })}
                    </ul>
                </div>
	        }
        </Tab>

	      <Tab eventKey="keyInfo" title={this.props.rentKeyInformationPage.caption}>
		      <div className="text-left list-unstyled list-unstyled--custom">
			      <span dangerouslySetInnerHTML={{ __html: this.props.rentKeyInformationPage.content }} />
		      </div>
	      </Tab>


	      {/*{this.getTollStickersWithoutEnc() &&*/}
        {/*  <Tab eventKey="highwaySign" title="Zaplacené dálniční známky">*/}
        {/*    <div className="text-left my-5 list-unstyled list-unstyled--custom">*/}
        {/*      <ul>*/}
        {/*          {this.getTollStickersWithoutEnc().map((item: TollSticker, key) => {*/}
        {/*              return (<li key={key}> {item.type.name}</li>);*/}
        {/*          })}*/}
        {/*      </ul>*/}
        {/*    </div>*/}
        {/*  </Tab>*/}
        {/*}*/}

        {/*{this.getEncStickers().length > 0 &&*/}
        {/*  <Tab eventKey="ENC" title="Mýtné krabičky ENC ve vozidle">*/}
        {/*      <SimpleText content="Mýtné krabičky ENC ve vozidle"/>*/}
        {/*      <div className="text-left my-5 list-unstyled list-unstyled--custom">*/}
        {/*          <ul>*/}
        {/*              {this.getEncStickers().map((item: TollSticker, key) => {*/}
        {/*                  return (<li key={key}> {item.type.name}</li>);*/}
        {/*              })}*/}
        {/*          </ul>*/}
        {/*      </div>*/}
        {/*  </Tab>*/}
        {/*}*/}

      </Tabs>
    );
  }

  //
  // getEncStickers(): Array<TollSticker> {
  //   let tollStickers = [];
  //   car.tollStickers.map((item: TollSticker, key) => {
  //       if (item.type.name === 'ENC (HR)') {
  //           tollStickers.push(item);
  //       }
  //       return item;
  //   });
  //   return tollStickers;
  // }
  //
  //
  // getTollStickersWithoutEnc(): Array<TollSticker> {
  //     let tollStickers = [];
  //     car.tollStickers.map((item: TollSticker, key) => {
  //         if (item.type.name !== 'ENC (HR)') {
  //             tollStickers.push(item);
  //         }
  //         return item;
  //     });
  //     return tollStickers;
  // }

}
