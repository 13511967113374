import React from 'react';
import { connect } from 'react-redux';
import compose from '../../hoc/composition';
import withContent, { IWithContent } from '../../hoc/Content';
import WpContent from '../../hoc/loaders/Wp';
import { IAppState } from '../../store/reducer';
import { toggleNavigation } from '../../store/actions';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../../components/Logo/Logo';
import styles from './TopNavBar.module.scss';
import HamburgerButton from '../../components/HamburgerButton/HamburgerButton';
// import { Link } from 'react-router-dom';
// import { HashLink as Link } from 'react-router-hash-link';
import BackendClient from "../../api/BackendClient";

interface ITopNavbarProps {
	isOpen: boolean,
	toggleNavigation: () => void
}

export default compose(
	withContent({
		content: WpContent.MENU_TOP_NAVBAR,
		renderWithoutData: true
	}),
	connect(
		(state: IAppState) => ({
			isOpen: state.isTopNavbarOpen
		}),
		{ toggleNavigation })
)((props: ITopNavbarProps & IWithContent) => {
	const navClasses = [styles.navbar];
	const {
		content,
		isOpen,
		toggleNavigation
	} = props

	const menuItems = content && content.map(item => (
  		<li key={item.ID} className={styles.menuItem}>
		    <a href={BackendClient.getBaseUrl() + item.url}>
			    {item.post_title}
		    </a>
  		</li>
	));

	if (isOpen) {
		navClasses.push(styles.topMenuOpened);
	}

	return (
		<nav className={navClasses.join(' ')}>
			<Container className="h-100">
				<Row className="h-100 align-items-center">
					<Col xs={9} lg={3}>
						<Logo />
					</Col>
					<Col className="text-right">
						<HamburgerButton clicked={toggleNavigation} isOpen={isOpen} />
						<div id="topMenu" className={styles.topMenu}>
							<ul className={styles.topMenu__menu}>
								{menuItems}
							</ul>
						</div>
					</Col>
				</Row>
			</Container>
		</nav>
	);
});
