import React, { PureComponent } from 'react';
import { ContentLoaderSuspense } from './Suspense';

export interface IWithContent {
	content: any
}

interface IWithContentOptions {
	content?: (props: any) => Promise<any>
	renderWithoutData?: boolean
}

interface ILoaderState {
	isLoading: boolean
	content: any
}

export default function withContent(options: IWithContentOptions = {}) {
	return Component => class WrappedContentLoader extends PureComponent<any, ILoaderState> {
		constructor(props: any) {
			super(props);
			this.state = {
				isLoading: null,
				content: null
			};
		}

		shouldLoad = () => {
			const content = this.props.content || options.content
			if (!content) {
				return;
			}
			this.setState({ isLoading: true }, async () => {
				try {
					ContentLoaderSuspense.onLoadStart();
					this.setState({
						isLoading: false,
						content: await content(this.props)
					});
				}
				finally {
					ContentLoaderSuspense.onLoadComplete();
				}
			});
		}

		componentDidMount() {
			this.shouldLoad();
		}

		componentDidUpdate(prevProps) {
			if (this.props.content !== prevProps.content) {
				this.setState({
					isLoading: null,
					content: null
				}, this.shouldLoad)
			}
		}

		render() {
			const { isLoading, content } = this.state;
			return (
				<>
					{(isLoading === false || options.renderWithoutData) && (
						<Component {...this.props} content={content} />
					)}
				</>
			);
		}
	};
}
