import { Loader, LoaderBaseOptions } from './base';
import BackendClient from '../../api/BackendClient';

const client = new BackendClient();

type ApiContentOptions = LoaderBaseOptions & {
	fetch: (client: BackendClient, props: any) => Promise<any>
}

export default function ApiContent(options: ApiContentOptions) {
	const { fetch } = options
	return Loader(async (props: any) => await fetch(client, props), options);
}
