import React from 'react';
import styles from './Heading.module.scss';

export default function heading (props: {title?: string, subtitle?: string}) {
	return (
		<React.Fragment>
			<h2 className={styles.h2}>{props.title}</h2>
			<h3 className={styles.h3}>{props.subtitle}</h3>
			<div className={styles.separator}/>
		</React.Fragment>
	)
}
