import moment from 'moment';
// import {number} from "prop-types";

export class ReservationDataHelper {


	public static reservationFromWithTime(reservationData: any) {
		let rentFrom = reservationData.rentFrom;
		rentFrom.setHours(reservationData.pickupTimeHour, reservationData.pickupTimeMinute);
		return rentFrom;
	}

	public static reservationToWithTime(reservationData: any) {
		let rentTo = reservationData.rentTo;
		rentTo.setHours(reservationData.returnTimeHour, reservationData.returnTimeMinute);
		return rentTo;
	}

	public static rentDaysDiff(reservationData: any): number {
		const fromDate = moment(this.reservationFromWithTime(reservationData));
		const toDate = moment(this.reservationToWithTime(reservationData));

		return this.daysDiff(fromDate, toDate);
	}


	public static daysDiff(fromDate: moment.Moment, toDate: moment.Moment) {
		const hoursDiff = toDate.diff(fromDate, 'minutes');


		let numberOfDays = parseInt('' + (hoursDiff / 60 / 24 ));

		if ( ( (hoursDiff / 60)  % 24 ) > 0) {
			numberOfDays += 1;
		}

		return numberOfDays;
	}




}
