import React, { Component } from 'react';
import {Tabs, Tab, Container, Row, Col, Button, Form} from 'react-bootstrap';
import { withRouter, RouteComponentProps } from 'react-router';
import RentInfoForm from '../RentInfoForm/RentInfoForm';
import ExtraEquipmentForm from '../ExtraEquipmentForm/ExtraEquipmentForm';
import PersonalInfoForm from '../PersonalInfoForm/PersonalInfoForm';
import BillInfoForm from '../BillInfoForm/BillInfoForm';
import NextInfoForm from '../NextInfoForm/NextInfoForm';
import PayForm from '../PayForm/PayForm';
import { connect } from 'react-redux';
import {IAppState, IReservationFormData} from '../../store/reducer';
import {
	Car,
	Country,
	PriceList,
	RentService,
	RentSettings/*, ReservationServiceInput*/
} from "autodevelo-api-client-ts";
import compose from "../../hoc/composition";
import {setReservationFormData, setLoading} from "../../store/actions";
import {ReservationSummary} from "../ReservationSummary/ReservationSummary";

interface IReservationTabsState {
	key: number,
	reservationFormData: IReservationFormData,
}

interface IReservationTabsProps {
	rentSettings: RentSettings;
	setReservationFormData: (data: object) => void,
	setLoading: (isLoading: boolean) => void,
	formData: any,
	countries: Array<Country>;
	content: Car,
	vehicleEquipmentList: any
}

class ReservationTabs extends Component<IReservationTabsProps & RouteComponentProps, IReservationTabsState> {
  constructor(props, context) {
    super(props, context);
    this.state = {
			key: 0,
			reservationFormData: {
				countries: [],
				otherCountries: [],
				carId: null, 									// number,
				services: props.vehicleEquipmentList, 								// Array<ReservationServiceInput>,
				destinationCountryId: null, 	// number,
				travelersCount: null,					// number,
				freeParking: null,						// boolean,
				rentFrom: null, 								// Date,
				rentTo: null, 									// Date,
				clientCountryId: null, 				// number,
				clientFirstname: '', 							// string,
				clientSurname: '',						// string,
				clientStreet: '', 						// string,
				houseNumber: '', 						// string,
				clientCity: '', 							// string,
				clientZip: '', 								// string,
				clientEmail: '', 							// string,
				clientPhone: '',							// string,
				payTypeId: null, 							// number,
				payTypeDepositId: null, 			// number,
				payTypeBailId: null, 					// number,
				isCarDelivery: null, 					// boolean,
				isCarTakeover: null, 					// boolean,
				clientIc: '' 									// string
			}
    };
	}

	submited = (index) => {
		this.setState({key: index});
	};


	getStartDate = () => {
		return this.props.formData['startDate'];
	};


    getEndDate = () => {
		return this.props.formData['endDate'];
	};



	saveFormData = (data) => {


		let formData = {...this.state.reservationFormData, ...data};

		// @todo asi by bylo lepsi si to prenaset v url adrese nez tohle
		formData['carId'] = this.props.content.id;
		formData['rentFrom'] = this.getStartDate().toDate();
		formData['rentTo'] = this.getEndDate().toDate();


		// remove equipments which are not available
		let availableServiceIds = [];
		this.props.content.rentServices.map((rentService: RentService) => {
			availableServiceIds.push(rentService.id);
        return rentService;
		});

		let validatedServices = {};
		for (let i in formData['services']) {
			let serviceData = formData['services'][i];
			if (availableServiceIds.indexOf(serviceData['idService']) !== -1) {
				validatedServices[serviceData['idService']] = serviceData;
			}
		}
		formData['services'] = validatedServices;
		this.setState({reservationFormData: formData});
		this.props.setReservationFormData(formData);
	};

	sendForm = (data) => {
		this.saveFormData(data);


		this.props.history.push('/vytvorit-rezervaci');
	};

  render() {
	const {setLoading, content, countries, rentSettings} = this.props;
	const priceList: PriceList = content.rentPrice.priceList;
    return (
			<section>
				<Container>
					<Row>
						<Col>
							<Tabs
								id="controlled-tab-example"
								activeKey={this.state.key}
								onSelect={key => this.setState({ key })}
							>
								<Tab eventKey="0" title="Údaje o výpujčce">
									<RentInfoForm
										car={this.props.content}
										rentFrom={this.getStartDate()}
										rentTo={this.getEndDate()}
										submit={this.submited}
										saveFormData={this.saveFormData}
										onChange={this.saveFormData}
										countries={countries}
										rentSettings={rentSettings}
									/>
								</Tab>
								<Tab eventKey="1" title="Extra výbava" disabled={this.state.key < 1}>
									<ExtraEquipmentForm submit={this.submited} content={this.props.content} saveFormData={this.saveFormData} />
								</Tab>
								<Tab eventKey="2" title="Osobní údaje" disabled={this.state.key < 2}>
									<PersonalInfoForm submit={this.submited} saveFormData={this.saveFormData}/>
								</Tab>
								<Tab eventKey="3" title="Fakturační údaje" disabled={this.state.key < 3}>
									<BillInfoForm submit={this.submited} saveFormData={this.saveFormData} setLoading={setLoading}/>
								</Tab>
								<Tab eventKey="4" title="Další údaje" disabled={this.state.key < 4}>
									<NextInfoForm
										submit={this.submited}
										saveFormData={this.saveFormData}
										countries={countries}
									/>
								</Tab>
								<Tab eventKey="5" title="Platba" disabled={this.state.key < 5}>
									<PayForm
										submit={this.submited}
										saveFormData={this.saveFormData}
										allowBail={priceList.hasBail}
										allowDeposit={priceList.hasDeposit}
									/>
								</Tab>
								<Tab eventKey="6" title="Shrnutí" disabled={this.state.key < 6}>
									<ReservationSummary
										submit={() => this.sendForm(this.state.reservationFormData)}
										reservationData={this.state.reservationFormData}
										car={this.props.content}
										countries={this.props.countries}
										settingExternalCarRental={this.props.rentSettings.settingExternalCarRental}
									/>
								</Tab>
							</Tabs>
						</Col>
					</Row>
				</Container>
			</section>
    );
  }
}



export default compose(
	withRouter,
	connect(
		(state: IAppState) => ({vehicleEquipmentList: state.reservationFormData['services'], reservationFormData: state.reservationFormData}),
		{ setReservationFormData, setLoading }
	)
)(ReservationTabs);
