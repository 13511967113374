import React from 'react';
import { Formik, } from 'formik';
import { Form, Button, Col } from 'react-bootstrap';
import * as yup from 'yup';

const phoneRegExp = /^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/;
const requiredError = 'Toto pole je povinné.';

const schema = yup.object({
	clientFirstname: yup.string().required(requiredError),
	clientSurname: yup.string().required(requiredError),
	clientEmail: yup.string().email('E-mailová adresa je ve špatném formátu.').required(requiredError),
	clientPhone: yup.string().matches(phoneRegExp, 'Telefonní číslo je ve špatném formátu.').required(requiredError),
	clientZip: yup.string().length(5, 'PSČ musí obsahovat 5 znaků').required(requiredError),
	clientCity: yup.string().required(requiredError),
	clientStreet: yup.string().required(requiredError),
	houseNumber: yup.string().required(requiredError),
	clientCountryId: yup.string().required('Vyberte prosím jednu z možností.'),
});

export default function PersonalInfoForm(props) {
	return (
		<Formik
			validationSchema={schema}
			onSubmit={(values, actions) => {
				actions.setSubmitting(false);
				props.saveFormData(values);
				props.submit(3);
			}}
			initialValues={{
				clientFirstname: '',
				clientSurname: '',
				clientStreet: '',
				houseNumber: '',
				clientCity: '',
				clientZip: '',
				clientEmail: '',
				clientPhone: '',
				clientCountryId: '0',
			}}
		>
		{({
			handleSubmit,
			handleChange,
			values,
			touched,
			errors,
			isSubmitting
		}) => (
			<Form noValidate onSubmit={handleSubmit}>
				<Form.Row>

					{/* Jméno */}

					<Form.Group as={Col} sm="6" lg="4" xl="3" controlId="personalInfoFormFirstName">
						<Form.Label>Jméno</Form.Label>
						<Form.Control
							type="text"
							name="clientFirstname"
							value={values.clientFirstname}
							onChange={handleChange}
							isValid={touched.clientFirstname && !errors.clientFirstname}
							isInvalid={touched.clientFirstname && !!errors.clientFirstname}
						/>
						<Form.Control.Feedback>Správně!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
							{errors.clientFirstname}
            </Form.Control.Feedback>
					</Form.Group>

					{/* Příjmení */}

					<Form.Group as={Col} sm="6" lg="4" xl="3" controlId="personalInfoFormLastName">
						<Form.Label>Příjmení</Form.Label>
						<Form.Control
							type="text"
							name="clientSurname"
							value={values.clientSurname}
							onChange={handleChange}
							isValid={touched.clientSurname && !errors.clientSurname}
							isInvalid={touched.clientSurname && !!errors.clientSurname}
						/>
						<Form.Control.Feedback>Správně!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
							{errors.clientSurname}
            </Form.Control.Feedback>
					</Form.Group>

					{/* Ulice: */}

					<Form.Group as={Col} sm="6" lg="4" xl="3" controlId="personalInfoFormStreet">
						<Form.Label>Ulice:</Form.Label>
						<Form.Control
							type="text"
							name="clientStreet"
							value={values.clientStreet}
							onChange={handleChange}
							isValid={touched.clientStreet && !errors.clientStreet}
							isInvalid={touched.clientStreet && !!errors.clientStreet}
						/>
						<Form.Control.Feedback>Správně!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
							{errors.clientStreet}
            </Form.Control.Feedback>
					</Form.Group>

					{/* Číslo popisné: */}

					<Form.Group as={Col} sm="6" lg="4" xl="3" controlId="personalInfoFormDescriptive">
						<Form.Label>Číslo popisné:</Form.Label>
						<Form.Control
							type="text"
							name="houseNumber"
							value={values.houseNumber}
							onChange={handleChange}
							isValid={touched.houseNumber && !errors.houseNumber}
							isInvalid={touched.houseNumber && !!errors.houseNumber}
						/>
						<Form.Control.Feedback>Správně!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
							{errors.houseNumber}
            </Form.Control.Feedback>
					</Form.Group>

					{/* Město: */}

					<Form.Group as={Col} sm="6" lg="4" xl="3" controlId="personalInfoFormCity">
						<Form.Label>Město:</Form.Label>
						<Form.Control
							type="text"
							name="clientCity"
							value={values.clientCity}
							onChange={handleChange}
							isValid={touched.clientCity && !errors.clientCity}
							isInvalid={touched.clientCity && !!errors.clientCity}
						/>
						<Form.Control.Feedback>Správně!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
							{errors.clientCity}
            </Form.Control.Feedback>
					</Form.Group>

					{/* PSČ: */}

					<Form.Group as={Col} sm="6" lg="4" xl="3" controlId="personalInfoFormZipCode">
						<Form.Label>PSČ:</Form.Label>
						<Form.Control
							type="text"
							name="clientZip"
							value={values.clientZip}
							onChange={handleChange}
							isValid={touched.clientZip && !errors.clientZip}
							isInvalid={touched.clientZip && !!errors.clientZip}
						/>
						<Form.Control.Feedback>Správně!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
							{errors.clientZip}
            </Form.Control.Feedback>
					</Form.Group>

					{/* Stát: */}

					<Form.Group as={Col} sm="6" lg="4" xl="6" controlId="personalInfoFormState">
						<Form.Label>Stát:</Form.Label>
						<Form.Control
							as="select"
							name="clientCountryId"
							value={values.clientCountryId}
							onChange={handleChange}
							isValid={touched.clientCountryId && !errors.clientCountryId}
							isInvalid={touched.clientCountryId && !!errors.clientCountryId}
						>
							<option value={0}>Česká republika</option>
							<option value={1}>Slovensko</option>
						</Form.Control>
						<Form.Control.Feedback>Děkujeme!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
							{errors.clientCountryId}
            </Form.Control.Feedback>
					</Form.Group>

					{/* Email */}

					<Form.Group as={Col} sm="6" lg="4" xl="3" controlId="personalInfoFormEmail">
						<Form.Label>Email</Form.Label>
						<Form.Control
							type="email"
							name="clientEmail"
							value={values.clientEmail}
							onChange={handleChange}
							isValid={touched.clientEmail && !errors.clientEmail}
							isInvalid={touched.clientEmail && !!errors.clientEmail}
						/>
						<Form.Control.Feedback>Správně!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
							{errors.clientEmail}
            </Form.Control.Feedback>
					</Form.Group>

					{/* Mobil */}

					<Form.Group as={Col} sm="6" lg="4" xl="3" controlId="personalInfoFormPhone">
						<Form.Label>Mobil</Form.Label>
						<Form.Control
							type="phone"
							name="clientPhone"
							value={values.clientPhone}
							onChange={handleChange}
							isValid={touched.clientPhone && !errors.clientPhone}
							isInvalid={touched.clientPhone && !!errors.clientPhone}
						/>
						<Form.Control.Feedback>Správně!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
              {errors.clientPhone}
            </Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Col} sm="6" lg="12" xl="6" className="text-center text-sm-right pt-3">
						<Button disabled={isSubmitting} className="mt-4" type="submit">Pokračovat</Button>
					</Form.Group>
				</Form.Row>
			</Form>
		)}
	</Formik>
	);
}
