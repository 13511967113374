import {Car} from "autodevelo-api-client-ts";
import cargo from './cargo.json';
import minibus from './minibus.json';
import passenger from './passenger.json';
import caravan from './caravan.json';
import trailer from './trailer.json';
import utility from './utility.json';
import other from './other.json';

const metadata = {cargo, minibus, passenger, caravan, trailer, utility, other}

export default metadata;

export function resolveCarImage(car: Car): string {
	if (car.modelGallery && car.modelGallery.images.length > 0) {
		return car.modelGallery.images[0].url;
	}

	let imgSrc = '/img/models/unknown.png';
	if (
		typeof(car.type) !== 'undefined' &&
		metadata[car.type] &&
		metadata[car.type].model[car.type]
	) {
		imgSrc = metadata[car.type].model[car.type];
	}
	return imgSrc;
}

export function resolveCarImages(car: Car): Array<string> {
	if (car.modelGallery && car.modelGallery.images.length > 0) {
		return car.modelGallery.images.map(i => i.url);
	}

	let imgSrc = '/img/models/unknown.png';
	if (
		typeof(car.type) !== 'undefined' &&
		metadata[car.type] &&
		metadata[car.type].model[car.type]
	) {
		imgSrc = metadata[car.type].model[car.type];
	}
	return [imgSrc];
}
