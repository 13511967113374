import React from 'react';
import withContent, { IWithContent } from '../../hoc/Content';
import ApiContent from '../../hoc/loaders/Api';
import compose from '../../hoc/composition';
import { connect } from 'react-redux';
import { IAppState } from '../../store/reducer';
import { withRouter } from 'react-router';
import ReservationPreview from "../../components/ReservationPreview/ReservationPreview";
import {ReservationServiceInput} from "autodevelo-api-client-ts";
import ErrorPage from "../ErrorPage/ErrorPage";
import {ReservationDataHelper} from "../../utils/ReservationDataHelper";
import DocumentTitle from "react-document-title";

function ReservationPreviewPage(props: IWithContent) {
	if (props.content.errors !== undefined) {
		return (
			<>
				<ErrorPage error={"Rezervaci se nepodařilo vytvořit. Zkuste to prosím později."}/>
			</>
		);
	}

	return (
		<DocumentTitle title={'Rezervace vytvořena | Dovolená s Fordem'}>
			<>
				<ReservationPreview
					reservation={props.content.reservation}
					settingExternalCarRental={props.content.rentSettings.settingExternalCarRental}
				/>
			</>
		</DocumentTitle>
	);
}


export default compose(
	withRouter,
	withContent({
		content: ApiContent({
			fetch: async (client, props) => {

				// validate
				let isEmpty = true;
				for (let key in props.reservationData) {
					if (props.reservationData[key] !== undefined && props.reservationData[key] !== null) {
						isEmpty = false;
					}
				}

				if (isEmpty) {
					return {
						errors: ['']
					};
				}



				const reservationData = props.reservationData;

				let rentFrom = ReservationDataHelper.reservationFromWithTime(reservationData);
				let rentTo = ReservationDataHelper.reservationToWithTime(reservationData);

				let services = Object.values(reservationData['services']).map((data) => {
					return new ReservationServiceInput(data['idService'], data['count'], data['priceDay'], data['priceDayTax']);
				});

				const allCountries = await client.getAllCountries();
				const countriesString = allCountries
					.filter(c => reservationData.countries.includes(String(c.id)) || reservationData.otherCountries.includes(String(c.id)))
					.map(c => c.name)
					.join(', ');

				try {

					return {
						reservation: await client.createReservation(
							reservationData.carId,
							services,
							reservationData.destinationCountryId,
							reservationData.pickupPlace,
							reservationData.returnPlace,
							reservationData.travelersCount,
							reservationData.freeParking === 'true',
							rentFrom,
							rentTo,
							1, // 1 = czech republic
							!!reservationData.clientIc ? reservationData.clientBillingCompany : null,
							reservationData.clientFirstname,
							reservationData.clientSurname,
							!!reservationData.clientIc ? reservationData.clientBillingStreet : reservationData.clientStreet,
							!!reservationData.clientIc ? reservationData.clientBillingNumber : reservationData.houseNumber,
							!!reservationData.clientIc ? reservationData.clientBillingCity : reservationData.clientCity,
							!!reservationData.clientIc ? reservationData.clientBillingZip : reservationData.clientZip,
							reservationData.clientEmail,
							reservationData.clientPhone,
							reservationData.payTypeId,
							reservationData.payTypeDepositId,
							reservationData.payTypeBailId,
							false,
							false,
							reservationData.clientIc ? reservationData.clientIc : null,
							countriesString,
							!!reservationData.clientIc ? (reservationData.clientFirstname + ' ' + reservationData.clientSurname) : null,
							!!reservationData.clientIc ? reservationData.clientStreet + ' ' + reservationData.houseNumber : null,
							!!reservationData.clientIc ? reservationData.clientCity : null,
							!!reservationData.clientIc ? reservationData.clientZip : null
						),
						rentSettings: await client.readRentSettings(),
					}
				} catch (err) {
					return {
						errors: [err]
					};
				}
			},
			cache: false
		})
	}),
	connect((state: IAppState) => ({reservationData: state.reservationFormData})),
)(ReservationPreviewPage);


