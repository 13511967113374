import {Car, SettingExternalCarRental} from "autodevelo-api-client-ts";

export default class CarHelper {

	public static printGearbox(car: Car): string {
		let gearbox = null;
		if (car.gearbox === Car.GEARBOX_AUTOMATIC) {
			gearbox = 'Automatická';
		} else if (car.gearbox === Car.GEARBOX_MANUAL) {
			gearbox = 'Manuální';
		}

		if (!gearbox && car.variant && car.variant.gearbox && car.variant.gearbox.name) {
			gearbox = car.variant.gearbox.name
		}

		return gearbox ? gearbox : 'Neuvedeno';
	}


	public static printTitle(car: Car): string {
		let title = `${car.model.vendor.name} ${car.model.name}`;

		if (car.variant && car.variant.equipmentLevel && car.variant.equipmentLevel.name) {
			title += ' ' + car.variant.equipmentLevel.name;
		}

		return title;
	}


	public static hasAirConditioning(car: Car, settingExternalCarRental: SettingExternalCarRental): boolean {
		const {airConditioningEquipmentIds, airConditioningFittingIds} = settingExternalCarRental;
		if (car.equipmentValues.some(value => airConditioningEquipmentIds.includes(value.equipment.id))) {
			return true;
		}

		if (car.fittingVariants.some(fittingVariant => airConditioningFittingIds.includes(fittingVariant.fitting.id))) {
			return true;
		}
		return false;
	}

}
