import React, { Component } from 'react';
import NumericInput from 'react-numeric-input';
import numericInputStyles from './NumericInputStyles';
import { Form } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import {PriceHelper} from "../../utils/PriceHelper";
import VehicleEquipmentImage from "../VehicleEquipmentImage/VehicleEquipmentImage";
import {RentService} from "autodevelo-api-client-ts";

interface IVehicleEquipmentItemProps {
	service: RentService;
	itemValue: any,
	onChange: any,
}

interface IVehicleEquipmentItemState {
	checked: boolean,
	currentCounterValue: number
}

export default class VehicleEquipmentItem extends Component<IVehicleEquipmentItemProps, IVehicleEquipmentItemState> {
	constructor(props) {
    super(props);
    this.state = {
		checked: this.props.itemValue > 0 ? true : false,
		currentCounterValue: this.props.itemValue,
    };
  }

	render() {
		const {service, onChange} = this.props;
		const {id, name, priceDay, priceDayTax, description} = service;

		const handleCheckbox = () => {
			if (this.state.currentCounterValue > 0) {
				handleChange(0);
			}
			if (this.state.currentCounterValue <= 0) {
				handleChange(1);
			}
		}

		const handleChange = (value) => {
			const {service} = this.props;
			onChange({
				...service,
				value,
			});
			this.setState({currentCounterValue: value});
			if (value > 0) this.setState({checked: true});
			if (value <= 0) this.setState({checked: false});
		}

		return (
			<Row className="my-4 align-items-center">
				<Col sm={7}>
					<Row className="align-items-center">
						<Col sm={3} className="text-right">
							<VehicleEquipmentImage equipmentId={id} />
						</Col>
						<Col sm={9}>
							<div className="d-inline-block align-middle">
								<div className="text-light text-bold">{name}</div>
								<div className="text-primary">{PriceHelper.formatPrice(priceDayTax)} / den</div>
								<div className="text-primary text-gray-500 small">{PriceHelper.formatPrice(priceDay)} bez DPH / den</div>
								{description &&
									<div className="text-primary text-gray-500 small mt-1">{description}</div>
								}
							</div>
						</Col>
					</Row>
				</Col>
				<Col>
					<NumericInput
						name={id}
						value={this.state.currentCounterValue}
						min={0}
						max={99}
						step={1}
						precision={0}
						size={5}
						maxLength={2}
						onChange={handleChange}
						style={numericInputStyles}
					/>
				</Col>
				<Col>
					<Form.Check
						aria-label={'option'+id}
						checked={this.state.checked}
						label={""}
						id={'item'+id}
						type="checkbox"
						onChange={handleCheckbox}
						custom
					/>
				</Col>
			</Row>
		)
	}
};
