import React, { useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from 'moment';

const DatePickerWithFormik = ({
  startDateId,
  endDateId,
  form: { setFieldValue, setFieldTouched, values },
  field,
  ...props
}) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const handleDatesChange = ({ startDate, endDate }) => {
    setFieldValue("startDate", startDate);
    setFieldValue("endDate", endDate);
  };

	const focusInput = (focusedInput) => {
		setFocusedInput(focusedInput);
	};

  let myProps = {};
  if (props.isOutsideRange !== undefined) {
    myProps['isOutsideRange'] = (calendarDay) => {
	    if (moment().diff(calendarDay) > 0) { // disable reservation in the past
	    	return true;
	    }
      return props.isOutsideRange(calendarDay, focusedInput);
    }
  }

	return (
    <div className="App">
      <DateRangePicker
        {...myProps}
        startDate={values["startDate"]}
        startDateId="tata-start-date"
        endDate={values["endDate"]}
        endDateId="tata-end-date"
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
				onFocusChange={focusedInput => focusInput(focusedInput)}
				startDatePlaceholderText="od"
				endDatePlaceholderText="do"
				displayFormat="DD. MM. YYYY"
				hideKeyboardShortcutsPanel
      />
    </div>
  );
};

export default DatePickerWithFormik;
