import React, { Component } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import compose from '../../hoc/composition';
import DatePickerWithFormik from "./DateRangePicker";
import MultiSelectWithFormik from "./MultiSelect";
import { Formik, Field} from 'formik';
import * as yup from 'yup';
import VehicleTypeSelect from '../../components/VehicleTypeSelect/VehicleTypeSelect';
import { setFormData } from '../../store/actions';
import { writeHash } from '../../store/hash';
import withContent from '../../hoc/Content';
import ApiContent from '../../hoc/loaders/Api';
import 'moment/locale/cs';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../assets/scss/react-dates-overrides.scss';
import metadata from '../../metadata';
import CheckboxGroup from './CheckboxGroup';
import { IAppState } from '../../store/reducer';
import moment from 'moment';
import {RentOpeningHours} from "autodevelo-api-client-ts/dist/client/entity/RentOpeningHours";
import {Car, CarLocation, Equipment, RentSettings} from "autodevelo-api-client-ts";
import {RentCarType} from "autodevelo-api-client-ts/dist/client/entity/RentCarType";


// Checkbox input
const Checkbox = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
  return (
		<Col sm="6" md="4" xl="3" className="text-center">
			<VehicleTypeSelect
				id={id}
				imgSrc={props.imgSrc}
				title={props.title}
				price={props.price}
				persons={props.persons}
				name={name}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
			/>
		</Col>
  );
};

interface ISearchFormProps {
	setFormData: (data: object) => void
}

interface ApiProps {
	content: {
		0: Array<RentCarType>,
		1: Array<CarLocation>,
		2: Array<Equipment>,
		3: RentSettings,
	};
}

interface ISearchFormState {
	isSubmitted: boolean,
	focusedInput: any
}

class SearchForm extends Component<ISearchFormProps & RouteComponentProps & ApiProps & IAppState, ISearchFormState> {
	constructor(props, context) {
		super(props, context);
		this.state = {
			focusedInput: null,
			isSubmitted: false
		}
	}

  isOutsideRange = (day, focusedInput: string) => {
    let isClosed = false;
    const openingHours: RentOpeningHours = this.props.content[3].openingHours;
    if (day === null) return false;

    const dayName = day.locale('en').format('dddd').toLowerCase();

    if (focusedInput === 'startDate') {
      isClosed = openingHours.rent[dayName].closed;
    } else if (focusedInput === 'endDate') {
      isClosed = openingHours.returnRent[dayName].closed;
    } else {
      console.error('Unknown input');
    }

    return isClosed;
  }


	handleSubmit = (data, actions) => {
		actions.setSubmitting(false);

		let endDate = data['endDate'].toDate();
		endDate.setHours(0, 0, 0, 0);
		data['endDate'] = moment(endDate);

		this.props.setFormData(data);
		this.props.history.push('/vysledky#' + writeHash(data, false));
	};

	render() {
		const schema = yup.object({
			personsCount: yup.string(),
			vehicleEquipment: yup.string(),
			vehicleLocation: yup.string(),
			startDate: yup.string('Prosím zvolte "od" datum').required('Prosím zvolte "od" datum').nullable(),
			endDate: yup.string('Prosím zvolte "do" datum').required('Prosím zvolte "do" datum').nullable()
		});

		const multiSelectOptions =
			this.props.content[2].map((vehicleEquipment) => (
				{label: vehicleEquipment.name, value: vehicleEquipment.id}
			));

		const formData = this.props.formData;

		return (
      <div className="section-shadow">
  			<div className="bg-white container py-5">
  				<Formik
  					validationSchema={schema}
  					onSubmit={this.handleSubmit}
  					initialValues={{
  						personsCount: formData.personsCount,
  						vehicleEquipment: formData.vehicleEquipment,
  						vehicleLocation: formData.vehicleLocation,
  						startDate: formData.startDate,
  						endDate: formData.endDate,
  						checkboxGroup: formData.checkboxGroup
  					}}
  				>
  					{({
  						handleSubmit,
  						handleChange,
  						values,
  						touched,
  						errors,
  						isSubmitting,
  						setFieldValue,
  						setFieldTouched
  					}) => (
  						<Form noValidate onSubmit={handleSubmit}>
  							<Form.Row className="text-center">
  								<Form.Group as={Col} controlId="searchFormDateRange">
  								<Form.Label>Zvolte datum:</Form.Label>
  								<Field
                    component={DatePickerWithFormik}
                    className="form-control"
                    isOutsideRange={this.isOutsideRange}
                	/>
  								{touched.startDate && errors.startDate && <div className="invalid-feedback d-block">{errors.startDate}</div>}
  								{touched.endDate && errors.endDate && <div className="invalid-feedback d-block">{errors.endDate}</div>}
  								</Form.Group>
  							</Form.Row>
  							<Form.Row className="text-center justify-content-center">
  								<Form.Group as={Col} sm={5} md={4} lg={3} xl={2} controlId="searchFormPersonsCount">
  									<Form.Label>Počet osob:</Form.Label>
  									<Form.Control
  										as="select"
  										name="personsCount"
  										value={values.personsCount}
  										onChange={handleChange}
  										isValid={touched.personsCount && !errors.personsCount}
  										isInvalid={touched.personsCount && !!errors.personsCount}
  									>
  										<option value=""></option>
  										<option value="1">1</option>
  										<option value="2">2</option>
  										<option value="3">3</option>
  										<option value="4">4</option>
  										<option value="5">5</option>
  										<option value="6">6</option>
  										<option value="7">7</option>
  										<option value="8">8</option>
  										<option value="9">9</option>
  									</Form.Control>
  									<Form.Control.Feedback>Děkujeme!</Form.Control.Feedback>
  									<Form.Control.Feedback type="invalid">
  										{errors.personsCount}
  									</Form.Control.Feedback>
  								</Form.Group>
  							</Form.Row>
  							<Form.Row className="justify-content-center">
  								<CheckboxGroup
  									id="checkboxGroup"
  									label="Vyberte vůz:"
  									value={values.checkboxGroup}
  									touched={touched.checkboxGroup}
  									onChange={setFieldValue}
  									onBlur={setFieldTouched}
  								>
  								{this.props.content[0]
									.map((vehicleType, key) => (
  									<Field
  										key={key}
  										id={vehicleType.type}
  										name="checkboxGroup"
  										component={Checkbox}
  										imgSrc={metadata[vehicleType.type] && metadata[vehicleType.type].icon}
  										title={metadata[vehicleType.type] && metadata[vehicleType.type].title}
  										price={vehicleType.minRentPrice}
  										persons={vehicleType.maxSeats}
  									/>
  								))}
  								</CheckboxGroup>
  							</Form.Row>
  							<Form.Row className="justify-content-center text-center">
  								<Form.Group as={Col} sm={6} xl={4}  controlId="searchFormVehicleEquipment">
  									<Form.Label>Prvky výbavy:</Form.Label>
  									<Field
  										component={MultiSelectWithFormik}
  										options={multiSelectOptions}
  										name="vehicleEquipment"
  										setFieldValue={setFieldValue}
  										values={values}
  									/>
  								</Form.Group>
							    {this.renderLocations(values, handleChange, touched, errors)}
  							</Form.Row>
						    <Form.Row>
							    {this.renderGearbox(values, handleChange, touched, errors)}
						    </Form.Row>
  							<Form.Row className="text-center">
  								<Button disabled={isSubmitting} className="mt-4 mx-auto" type="submit">Hledat</Button>
  							</Form.Row>
  						</Form>
  					)}
  				</Formik>
  			</div>
      </div>
		);
	}

	renderLocations = (values, handleChange, touched, errors) => (
		<Form.Group as={Col} sm={6} xl={4} controlId="searchFormVehicleLocation">
			<Form.Label>Vyberte lokaci vozu:</Form.Label>
			<Form.Control
				as="select"
				name="vehicleLocation"
				value={values.vehicleLocation}
				onChange={handleChange}
				isValid={touched.vehicleLocation && !errors.vehicleLocation}
				isInvalid={touched.vehicleLocation && !!errors.vehicleLocation}
			>
				<option value=""></option>
				{this.props.content[1].map((vehicleLocation, key) => (
					<option key={key} value={vehicleLocation.id}>{vehicleLocation.name}</option>
				))}
			</Form.Control>
			<Form.Control.Feedback>Děkujeme!</Form.Control.Feedback>
			<Form.Control.Feedback type="invalid">
				{errors.vehicleLocation}
			</Form.Control.Feedback>
		</Form.Group>
	);

	renderGearbox = (values, handleChange, touched, errors) => (
		<Col sm={{span: 6, offset: 4}} xl={{span: 4, offset: 4}}>
		<Form.Group controlId="searchFormVehicleGearbox" className={'text-center mt-2'}>
				<Form.Label>Převodovka</Form.Label>
				<Form.Control
					as="select"
					name="gearbox"
					value={values.gearbox}
					onChange={handleChange}
					isValid={touched.gearbox && !errors.gearbox}
					isInvalid={touched.gearbox && !!errors.gearbox}
				>
					<option value=""></option>
					<option key={Car.GEARBOX_MANUAL} value={Car.GEARBOX_MANUAL}>Manuální</option>
					<option key={Car.GEARBOX_AUTOMATIC} value={Car.GEARBOX_AUTOMATIC}>Automatická</option>
				</Form.Control>
				<Form.Control.Feedback>Děkujeme!</Form.Control.Feedback>
				<Form.Control.Feedback type="invalid">
					{errors.vehicleLocation}
				</Form.Control.Feedback>
		</Form.Group>
		</Col>
	);

}

export default compose(
	withContent({
			content: ApiContent({
				fetch: client => Promise.all([
					client.readAvailableRentCarTypes(),
					client.readAvailableCarLocations(),
					client.readAvailableCarEquipments(),
					client.readRentSettings()
				])
			})
	}),
	withRouter,
	connect(
		(state: IAppState) => ({formData: state.formData}),
		{ setFormData }
	)
)(SearchForm);
