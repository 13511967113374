import React from 'react';
import withProgress from '../../hoc/Suspense';
import styles from './AppLoader.module.scss';
import Dots from "./Dots";

export default withProgress(({ isLoading, children/*, progress */ }) => {
	return (
		<div className={styles.appLoader + ' ' + (isLoading ? styles.appLoaderVisible : '')}>
			<div>{children}</div>
			<Dots/>
		</div>
	);
});
