import React from "react";

const SvgCameraIcon = props => (
  <svg viewBox="0 0 22 18" {...props}>
    <path
      d="M21.3 16.2c0 .6-.5 1-1 1H1.8c-.6 0-1-.5-1-1V5.4c0-.6.5-1 1-1h3.6L6.9.7h8.3l1.5 3.7h3.6c.6 0 1 .5 1 1v10.8zM2.9 2.6h.7v1.1h-.7V2.6zm17.4 1.1h-3.1L15.7 0H6.4L4.9 3.7h-.5V1.8H2.2v1.8h-.4C.8 3.7 0 4.5 0 5.4v10.8c0 1 .8 1.8 1.8 1.8h18.5c1 0 1.8-.8 1.8-1.8V5.4c-.1-.9-.9-1.7-1.8-1.7M11 15.8c-3.2 0-5.9-2.6-5.9-5.9S7.8 4 11 4s5.9 2.6 5.9 5.9-2.6 5.9-5.9 5.9m0-12.5c-3.6 0-6.6 3-6.6 6.6 0 3.6 3 6.6 6.6 6.6s6.6-3 6.6-6.6c0-3.6-2.9-6.6-6.6-6.6m0 10.3c-2 0-3.7-1.6-3.7-3.7 0-2 1.6-3.7 3.7-3.7s3.7 1.6 3.7 3.7c0 2-1.7 3.7-3.7 3.7m0-8.1c-2.4 0-4.4 2-4.4 4.4 0 2.4 2 4.4 4.4 4.4s4.4-2 4.4-4.4c0-2.4-2-4.4-4.4-4.4m8.1 1.8c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7.4 0 .7.3.7.7 0 .4-.3.7-.7.7m0-2.2c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5"
      fill="#09c"
    />
  </svg>
);

export default SvgCameraIcon;
