import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import VehicleParameters from '../../components/VehicleParameters/VehicleParameters';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
// import metadata from '../../metadata';
import VehicleImage from "../../components/VehicleImage/VehicleImage";
import {Car, SettingExternalCarRental} from "autodevelo-api-client-ts";

interface Props {
	car: Car,
	settingExternalCarRental: SettingExternalCarRental,
}

export default function ReservationHero(props: Props) {
	const {car, settingExternalCarRental} = props;

	return (
		<section className="my-5">
			<Container>
				<Row className="align-items-center justify-content-center">
					<Col sm={12} className="text-center">
						<h1 className="text-darkBlue text-uppercase h2">Rezervace</h1>
						<VehicleImage car={car} class={'my-4 img-fluid'}/>
					</Col>
					<Col lg={6} className="col-xxl-6">
						<VehicleParameters
							car={car}
							settingExternalCarRental={settingExternalCarRental}
						/>
					</Col>
					<Col>
						<OrderSummary content={car}/>
					</Col>
				</Row>
			</Container>
		</section>
	);
}
