export type LoaderBaseOptions = {
	dest?: string[]
	cache?: boolean
}

export function Loader(fetch: (props: any) => Promise<any>, { dest = [], cache = true }: LoaderBaseOptions) {
	let content = null;
	return async (props: any) => {
		let tmp = content
		if (!content) {
			tmp = await fetch(props);
			for (let i = 0; i < dest.length; ++i) {
				if (tmp) {
					tmp = tmp[dest[i]];
				}
			}
			if (cache) {
				content = tmp;
			}
		}
		return tmp;
	}
}
