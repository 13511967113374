import React from 'react';
import withContent from '../../hoc/Content';
import WpContent from '../../hoc/loaders/Wp';
import Parallax from 'react-rellax';
import Heading from '../../components/Heading/Heading';
import { Container, Row, Col } from 'react-bootstrap';
import SimpleText from '../../components/SimpleText/SimpleText';
import frontImg from '../../assets/img/parallax1_front.png';
import backImg from '../../assets/img/parallax1_back.png';
import styles from './AdvantagesSection.module.scss';

export default withContent({ content: WpContent.ACF_ADVANTAGES_SECTION })(({ content: {
	advHeading,
	advSubheading,
	advContent
}}) => {
	return (
		<section id="vyhody" className="my-5">
			<Container>
				<Row>
					<Col className="text-center">
						<Heading title={advHeading} subtitle={advSubheading}/>
						<SimpleText className="text-left mt-4 list-unstyled list-unstyled--custom" content={advContent}/>
					</Col>
				</Row>
			 	<Row>
					<Col>
						<div className={styles.wrapper}>
							<Parallax className={styles.parallax} speed={0.5} percentage={2}>
								<img className={styles.back} src={backImg} alt="back"/>
							</Parallax>
							<Parallax className="img-fluid" speed={-0.5} percentage={0}>
								<img className="img-fluid" src={frontImg} alt="front"/>
							</Parallax>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
});
