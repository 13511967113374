import React from 'react';
import styles from './MiniLoader.module.scss';

const MiniDots = () => {
	return (
		<span>
			<div className={styles.miniDot} />
			<div className={styles.miniDot} />
			<div className={styles.miniDot} />
		</span>
	);
};

export default MiniDots;
