import React from 'react';
import styles from './HamburgerButton.module.scss';

let hamburgerClasses = [styles.hamburger, styles['hamburger--spring']];

export default function hamburgerButton (props) {
	if (props.isOpen === true) {
		hamburgerClasses.push(styles['is-active']);
	}
	if (props.isOpen === false) {
		hamburgerClasses = [styles.hamburger, styles['hamburger--spring']];
	}

	return (
		<button onClick={props.clicked} id="topMenuOpen" className={hamburgerClasses.join(' ')}>
			<span className={styles['hamburger-box']}>
				<span className={styles['hamburger-inner']}></span>
			</span>
		</button>
	);
};

