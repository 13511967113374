import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SimpleText from '../SimpleText/SimpleText';
import styles from './ContactMap.module.scss';

export default function ContactMap(props: {img?: any, content?: string}) {
  return (
    <Row className="mt-5 no-gutters">
      <Col lg="8">
		  <iframe style={{width: '100%', height: '100%', border: 'none'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2591.4208421900207!2d17.944601848890507!3d49.495446204686466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDnCsDI5JzQ1LjQiTiAxN8KwNTYnNDkuOSJF!5e0!3m2!1scs!2scz!4v1625730525368!5m2!1scs!2scz" width="600" height="450" allowFullScreen></iframe>
			{/*<img src={props.img.url} alt="Mapa" className="w-100 h-100 img-fluid img-cover" />*/}
      </Col>
      <Col className="bg-primary text-white px-4 py-5 p-lg-5">
        <Row className="justify-content-center">
          <Col sm="8" md="6" lg="12">
            <SimpleText content={props.content} className={styles.contactContent} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
};
