import React from 'react';
import withContent from '../../hoc/Content';
import WpContent from '../../hoc/loaders/Wp';
import strip_tags from '../../assets/js/stripTags';
import styles from './HomeHero.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import TitleH1 from '../../components/Titles/TitleH1';
import SearchForm from '../SearchForm/SearchForm';

export default withContent({ content: WpContent.ACF_HOME_HERO })(({ content: {
	title
}}) => {
	const titleH1 = strip_tags(title, '<b><i><u><strong>');

	return (
	<section id="uvod" className={styles.homeHero}>
		<Container className="py-5">
			<Row className="justify-content-center">
				<Col md={9} xl={6} className="text-center">
					<TitleH1 title={titleH1}/>
				</Col>
				<Col lg={10} className="mt-4">
					<SearchForm />
				</Col>
			</Row>
		</Container>
	</section>
	);
});
