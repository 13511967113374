import moment from 'moment'
import {ActionType} from "./actions";

export interface ISearchFormData {
	id: any,
	startDate: any,
	endDate: any,
	personsCount: any,
	checkboxGroup: any
	vehicleEquipment: any,
	vehicleLocation: any,
	nextInfo?: any;
	gearbox?: string;
}

export interface IReservationFormData {
	countries: Array<string>,
	otherCountries: Array<string>,
	services: object,
	carId: number,
	pickupPlace?: number,
	returnPlace?: number,
	destinationCountryId: number,
	travelersCount: number,
	freeParking: string,
	rentFrom: Date,
	rentTo: Date,
	clientFirstname: string,
	clientSurname: string,
	clientStreet: string,
	houseNumber: string,
	clientCity: string,
	clientZip: string,
	clientEmail: string,
	clientPhone: string,
	payTypeId: number,
	payTypeDepositId: number,
	payTypeBailId: number,
	clientIc?: string
	nextInfo?: string,
	clientBillingCompany?: string,
	clientBillingCity?: string,
	clientBillingStreet?: string,
	clientBillingNumber?: string,
	clientBillingZip?: string,
	clientCountryId?: number,
	isCarDelivery?: boolean,
	isCarTakeover?: boolean,
}

export interface IAppState {
	isTopNavbarOpen: boolean,
	vehicleEquipmentList: object,
	formData: ISearchFormData,
	reservationFormData: IReservationFormData,
	layout: {
		isLoading: boolean,
	}
}


const initialEndDate = moment()
	.endOf('isoWeek')
	.add(1, 'week')
	.add(-2, 'day')
	.set({hour:0,minute:0,second:0,millisecond:0});

const initialState: IAppState = {
	isTopNavbarOpen: false,
	vehicleEquipmentList: {},
	formData: {
		id: null,
		startDate: moment().startOf('isoWeek').add(1, 'week'),
		endDate: initialEndDate,
		personsCount: '',
		checkboxGroup: [],
		vehicleEquipment: [],
		vehicleLocation: '',
		gearbox: null,
	},
	reservationFormData: {
		countries: [],
		otherCountries: [],
		services: {},
		carId: null,
		destinationCountryId: null,
		pickupPlace: null,
		returnPlace: null,
		travelersCount: null,
		freeParking: null,
		rentFrom: null,
		rentTo: null,
		clientFirstname: null,
		clientSurname: null,
		clientStreet: null,
		houseNumber: null,
		clientCity: null,
		clientZip: null,
		clientEmail: null,
		clientPhone: null,
		payTypeId: null,
		payTypeDepositId: null,
		payTypeBailId: null,
		nextInfo: null,
		clientBillingCompany: null,
		clientBillingCity: null,
		clientBillingStreet: null,
		clientBillingNumber: null,
		clientBillingZip: null
	},
	layout: {
		isLoading: false,
	}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionType.TOGGLE_NAVIGATION:
			return {
				...state,
				isTopNavbarOpen: !state.isTopNavbarOpen
			};

		case ActionType.SET_COUNTER:
			let vehicleEquipmentList = state.reservationFormData['services'] || {};
			vehicleEquipmentList[action.value.id] = {
				count: action.value.value,
				idService: action.value.id,
				priceDay: action.value.priceDay,
				priceDayTax: action.value.priceDayTax,
				name: action.value.name,
				description: action.value.description,
			};
			return {
				...state,
				reservationFormData: state.reservationFormData
			};

		case ActionType.SET_FORM:
			return {
				...state,
				formData: action.data
			};

		case ActionType.SET_RESERVATION_FORM:
			return {
				...state,
				reservationFormData: action.data
			};

		case ActionType.SET_LOADING:
			return {
				...state,
				layout: {
					isLoading: action.isLoading,
				}
			};

		default:
			return state;
	}
}
