import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import VehiclePreview from '../../components/VehiclePreview/VehiclePreview';
import VehicleParameters from '../../components/VehicleParameters/VehicleParameters';
import { readHash, writeHash } from '../../store/hash';
import styles from '../../components/VehicleListItem/VehicleListItem.module.scss';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import {PriceHelper} from "../../utils/PriceHelper";
import DateHelper from "../../utils/DateHelper";
import {DiscountCircle} from "../../components/DiscountCircle/DiscountCircle";
import {RentSettings} from "autodevelo-api-client-ts";

function DetailHero(props: RouteComponentProps & {content: any}) {
	const reservationLink = '/rezervace#' + writeHash(readHash(), false);
	const car = props.content[0];
	const readRentSettings: RentSettings = props.content[5];
	const percentageDiscount = car.rentPrice.calculatePercentageDiscount();

	return (
		<section className="my-5">
			<Container>
				<Row className="align-items-center justify-content-center">
					<Col md={6} lg={4} xl={4}>
						{(percentageDiscount && car.isAvailalbeForRent()) && <DiscountCircle percentageDiscount={percentageDiscount}/>}
						<VehiclePreview car={car} />
					</Col>
					<Col sm={8} lg={5} xl={5} className="col-xxl-6">
						<VehicleParameters
							car={car}
							settingExternalCarRental={readRentSettings.settingExternalCarRental}
						/>
					</Col>
					<Col sm={4} lg={3} className="text-center col-xxl-2">
						<div className={styles.priceHeadign}>

							{car.isAvailalbeForRent() &&
								<>Cena za {car.rentPrice.days} dní nájmu:</>
							}

							{!car.isAvailalbeForRent() &&
								<>Vůz není možné zapůjčit</>
							}
						</div>

						{car.isAvailalbeForRent() &&
							<div className={styles.price}>{PriceHelper.formatPrice(car.rentPrice.price)}</div>
						}

						{car.isAvailalbeForRent() && car.rentPrice.priceDiscount > 0 &&
							<div className={styles.priceNoDiscount}>{PriceHelper.formatPrice(car.rentPrice.priceNoDiscount)}</div>
						}

						{car.isAvailalbeForRent() &&
							<Link to={reservationLink} className="btn btn-primary my-2">Rezervovat</Link>
						}


						<div className={styles.priceHeadign}>
							{DateHelper.formatMomentDate(props['formData']['startDate'])} - {DateHelper.formatMomentDate(props['formData']['endDate'])}
						</div>


						<Link to="/" className="d-block">upravit datum</Link>
					</Col>
				</Row>
			</Container>
		</section>
	);
}

export default withRouter((DetailHero));
