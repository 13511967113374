import React from 'react';
import withContent from '../../hoc/Content';
import WpContent from '../../hoc/loaders/Wp';
import { Container, Row, Col } from 'react-bootstrap';
import Heading from '../../components/Heading/Heading';
import ContactMap from '../../components/ContactMap/ContactMap';
import Card from '../../components/Card/Card';

export default withContent({ content: WpContent.ACF_CONTACT_SECTION })(({content: {
	contactHeading,
	contactSubheading,
  contactMap,
  contactText,
	contactCards
}}) => {
	let cards = null;
	if (Array.isArray(contactCards)) {
		cards = contactCards.map(({ icon: { id, url, alt }, title, text, info }) => (
			<Col key={id} lg={6} xl={4}>
				<Card
					icon={url}
					iconAlt={alt}
					title={title}
					text={text}
					info={info}
					isCentered={true}
				/>
			</Col>
		));
	} else {
		console.error('contactCards not found');
	}


	return (
		<section id="kontakt" className="mt-5">
			<Container>
				<Row>
					<Col className="text-center mb-4">
						<Heading title={contactHeading} subtitle={contactSubheading}/>
					</Col>
				</Row>
      </Container>
      <Container>
				<Row className="justify-content-center">
					{cards}
				</Row>
			</Container>
      <ContactMap img={contactMap} content={contactText} />
		</section>
	);
});

