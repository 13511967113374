import React, { Component } from 'react';
import { Formik, } from 'formik';
import { Form, Button, Row, Col } from 'react-bootstrap';
import * as yup from 'yup';
import BackendClient from "../../api/BackendClient";
import {isEmpty} from "../../utils/Utils";
import styles from './BillInfoForm.module.scss';

interface IPersonalInfoFormState {
	checked: boolean,
	clientIc: string;
	clientBillingCompany: string;
	clientBillingCity: string;
	clientBillingStreet: string;
	clientBillingNumber: string;
	clientBillingZip: string;
}

interface IPersonalInfoFormProps {
	submit: any,
	saveFormData: any
	setLoading(isLoading: boolean): void;
}

export default class PersonalInfoForm extends Component<IPersonalInfoFormProps, IPersonalInfoFormState> {

	client: BackendClient;


	constructor(props) {
	    super(props);
	    this.client = new BackendClient();

	    this.state = {
			checked: false,
	        clientIc: '',
		    clientBillingCompany: '',
		    clientBillingCity: '',
		    clientBillingStreet: '',
		    clientBillingNumber: '',
		    clientBillingZip: ''
		};
	}


	handleChangeChecked = () => {
		this.setState({
			checked: !this.state.checked
		})
	};


	setFormValues = (values, setValues) => {
		this.setState(values);
		setValues(values);
	};



	handleLoadAresData = async (ic, setValuesFunction) => {
		const {setLoading} = this.props;

		// reset form
		this.setFormValues({
			clientBillingCompany: '',
			clientBillingCity: '',
			clientBillingStreet: '',
			clientBillingNumber: '',
			clientBillingZip: ''
		}, setValuesFunction);

		setLoading(true);
		let data = await this.client.getCompanyDataFromAres(ic);
		data = data['data'];

		if (isEmpty(data)) {
			setLoading(false);
			alert('Firmu se nepodařilo načíst z ARES');
			return;
		}

		const newValues = {
			clientIc: ic,
			clientBillingCompany: data['company'],
			clientBillingCity: data['city'],
			clientBillingStreet: data['street'],
			clientBillingNumber: data['number'],
			clientBillingZip: data['zip'],
		};

		this.setFormValues(newValues, setValuesFunction);
		setLoading(false);
		return false;
	};


	render() {

		const requiredError = 'Toto pole je povinné.';

		const schema = yup.object({
			clientIc: yup.string().required(requiredError),
			clientBillingCompany: yup.string().required(requiredError),
			clientBillingCity: yup.string().required(requiredError),
			clientBillingStreet: yup.string().required(requiredError),
			clientBillingNumber: yup.string().required(requiredError),
			clientBillingZip: yup.string().length(5, 'PSČ musí obsahovat 5 znaků').required(requiredError),

		});

		const hidden = this.state.checked ? '' : 'd-none';

		const nextStep = () => {
			this.props.submit(4);
		};

		return (
			<Formik
				validationSchema={schema}
				onSubmit={(values, actions) => {
					actions.setSubmitting(false);
					this.props.saveFormData(values);
					this.props.submit(4);
				}}
				initialValues={{
					clientIc: '',
					clientBillingCity: '',
					clientBillingCompany: '',
					clientBillingStreet: '',
					clientBillingNumber: '',
					clientBillingZip: '',
				}}
			>
			{({
				handleSubmit,
				setValues,
				handleChange,
				values,
				touched,
				errors,
				isSubmitting
			}) => (
				<Form noValidate onSubmit={handleSubmit}>
					<Row>
						<Col sm={12} md={8} lg={6}>
							<Form.Row>
								<Form.Group as={Col} controlId="billInfoFormIC">
									<Form.Check onChange={this.handleChangeChecked} className="p-0 mx-3" type="checkbox" id={'ICCheck'} custom label={'Chci vystavit daňový doklad a vyplnit tedy fakturační údaje'}/>
								</Form.Group>
							</Form.Row>
							<Form.Row className={hidden}>
								<Form.Group as={Col} sm={8} controlId="billInfoFormIC">
									<Form.Label>IČ</Form.Label>
									<Form.Control
										type="text"
										name="clientIc"
										value={values.clientIc}
										onChange={handleChange}
										isValid={touched.clientIc && !errors.clientIc}
										isInvalid={touched.clientIc && !!errors.clientIc}
									/>
									<Form.Control.Feedback>Správně!</Form.Control.Feedback>
									<Form.Control.Feedback type="invalid">
										{errors.clientIc}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group as={Col} sm={12} controlId="billInfoFormIC">
									<div className={styles['ares-btn'] + ' text-primary'} onClick={() => this.handleLoadAresData(values.clientIc, setValues)}>Načíst z Aresu</div>
								</Form.Group>
							</Form.Row>


							<Form.Row className={hidden}>

								<Form.Group as={Col} sm={6} controlId="billInfoFormCompany">
									<Form.Label>Společnost</Form.Label>
									<Form.Control
										type="text"
										name="clientBillingCompany"
										value={values.clientBillingCompany}
										onChange={handleChange}
										isValid={touched.clientBillingCompany && !errors.clientBillingCompany}
										isInvalid={touched.clientBillingCompany && !!errors.clientBillingCompany}
									/>
									<Form.Control.Feedback>Správně!</Form.Control.Feedback>
									<Form.Control.Feedback type="invalid">
										{errors.clientBillingCompany}
									</Form.Control.Feedback>
								</Form.Group>



								<Form.Group as={Col} sm={6} controlId="billInfoFormCity">
									<Form.Label>Město</Form.Label>
									<Form.Control
										type="text"
										name="clientBillingCity"
										value={values.clientBillingCity}
										onChange={handleChange}
										isValid={touched.clientBillingCity && !errors.clientBillingCity}
										isInvalid={touched.clientBillingCity && !!errors.clientBillingCity}
									/>
									<Form.Control.Feedback>Správně!</Form.Control.Feedback>
									<Form.Control.Feedback type="invalid">
										{errors.clientBillingCity}
									</Form.Control.Feedback>
								</Form.Group>


								<Form.Group as={Col} sm={4} controlId="billInfoFormStreet">
									<Form.Label>Ulice</Form.Label>
									<Form.Control
										type="text"
										name="clientBillingStreet"
										value={values.clientBillingStreet}
										onChange={handleChange}
										isValid={touched.clientBillingStreet && !errors.clientBillingStreet}
										isInvalid={touched.clientBillingStreet && !!errors.clientBillingStreet}
									/>
									<Form.Control.Feedback>Správně!</Form.Control.Feedback>
									<Form.Control.Feedback type="invalid">
										{errors.clientBillingStreet}
									</Form.Control.Feedback>
								</Form.Group>


								<Form.Group as={Col} sm={4} controlId="billInfoFormNumber">
									<Form.Label>Číslo popisné</Form.Label>
									<Form.Control
										type="text"
										name="clientBillingNumber"
										value={values.clientBillingNumber}
										onChange={handleChange}
										isValid={touched.clientBillingNumber && !errors.clientBillingNumber}
										isInvalid={touched.clientBillingNumber && !!errors.clientBillingNumber}
									/>
									<Form.Control.Feedback>Správně!</Form.Control.Feedback>
									<Form.Control.Feedback type="invalid">
										{errors.clientBillingNumber}
									</Form.Control.Feedback>
								</Form.Group>


								<Form.Group as={Col} sm={4} controlId="billInfoFormZip">
									<Form.Label>PSČ</Form.Label>
									<Form.Control
										type="text"
										name="clientBillingZip"
										value={values.clientBillingZip}
										onChange={handleChange}
										isValid={touched.clientBillingZip && !errors.clientBillingZip}
										isInvalid={touched.clientBillingZip && !!errors.clientBillingZip}
									/>
									<Form.Control.Feedback>Správně!</Form.Control.Feedback>
									<Form.Control.Feedback type="invalid">
										{errors.clientBillingZip}
									</Form.Control.Feedback>
								</Form.Group>

							</Form.Row>

						</Col>
						<Col className="d-flex flex-column align-items-end justify-content-between">
							<div onClick={nextStep} className="btn btn-outline-primary px-5">Přeskočit</div>
							<Button disabled={isSubmitting} className={"mt-3 " + hidden} type="submit">Pokračovat</Button>
						</Col>
					</Row>
				</Form>
			)}
		</Formik>
		);
	}
}
