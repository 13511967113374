import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";

import TopNavbar from '../../containers/TopNavbar/TopNavbar';
import Footer from '../../containers/Footer/Footer';
import AppLoader from '../AppLoader/AppLoader';
import Logo from '../Logo/Logo';
import {IAppState} from "../../store/reducer";
import MiniLoader from "../MiniLoader/MiniLoader";

interface Props {
	layoutLoading: boolean;
}

class Layout extends React.Component<Props> {
	public render(): React.ReactNode {
		const {layoutLoading} = this.props;
		return (
			<>
				<TopNavbar />
				<main>{this.props.children}</main>
				<AppLoader isLoading={layoutLoading}>
					<Logo height={120}/>
				</AppLoader>
				<MiniLoader show={layoutLoading}/>
				<Footer />
			</>
		);
	}
}

const mapStateToProps = (state: IAppState) => ({
	layoutLoading: state.layout.isLoading,
});

export default compose(
	connect(mapStateToProps),
)(Layout) as React.ComponentType;
