import React from 'react';
import { Formik, } from 'formik';
import { Form, Button, Col } from 'react-bootstrap';
import VehicleEquipmentList from '../VehicleEquipmentList/VehicleEquipmentList';
import {Car} from "autodevelo-api-client-ts";
// import {IAppState} from "../../store/reducer";
// import {connect} from "react-redux";
// import {compose} from "redux";

interface IExtraEquipmentFormProps {
	saveFormData: (values) => void;
	content: Car;
	submit: any,
}

export default function ExtraEquipmentForm(props: IExtraEquipmentFormProps) {
	return (
		<Formik
			onSubmit={(values, actions) => {
				actions.setSubmitting(false);
				props.submit(2);
			}}
			initialValues={{
				// pickupPlace: '',
			}}
		>
		{({
			handleSubmit,
			isSubmitting
		}) => (
			<Form noValidate onSubmit={handleSubmit}>
				<Form.Row className="mb-lg--5">
					<Form.Group as={Col} sm={12} className="text-right mb-lg-0">
					</Form.Group>
				</Form.Row>

				{props.content.rentServices.length === 0 &&
					<div className={'mt-4'}>K tomuto vozu není dostupná žádná extra výbava</div>
				}

				{props.content.rentServices.length > 0 &&
					<Form.Row className="justify-content-center">
						<Form.Group as={Col} lg={7}>
							<VehicleEquipmentList content={props.content}/>
						</Form.Group>
					</Form.Row>
				}

				<Form.Row>
					<Form.Group as={Col} sm={12} className="text-right">
						<Button disabled={isSubmitting} className="mt-4 mx-auto" type="submit">Pokračovat</Button>
					</Form.Group>
				</Form.Row>
			</Form>
		)}
	</Formik>
	);
}
