import React from 'react';
import withContent from '../../hoc/Content';
import WpContent from '../../hoc/loaders/Wp';
import { Container, Row, Col } from 'react-bootstrap';
import Heading from '../../components/Heading/Heading';
import SimpleText from '../../components/SimpleText/SimpleText';
import ReferenceBlock from '../../components/ReferenceBlock/ReferenceBlock';

export default withContent({ content: WpContent.ACF_ABOUT })(({ content: {
	aboutHeading,
	aboutSubheading,
	aboutArticle,
	aboutRef,
	aboutRefAuthor,
	aboutImg,
  aboutRefImgs
}}) => (
	<section id="o-nas">
		<Container className="py-5">
			<Row className="text-center text-lg-left justify-content-between">
				<Col lg={7} xl={6}>
					<Heading title={aboutHeading} subtitle={aboutSubheading} />
					<SimpleText content={aboutArticle} className={"text-light mt-4"}/>
				</Col>
				<Col lg={5} className="text-lg-right">
					<img className="mt-3 mt-lg-0 img-fluid"src={aboutImg.sizes.medium_large} alt={aboutImg.alt}/>
				</Col>
			</Row>
			<Row className="justify-content-center mt-5 text-center">
				<Col sm={6} lg={4} className="pr-sm-0">
					<img className="img-fluid"src={aboutRefImgs.imgLeft.sizes.medium_large} alt={aboutRefImgs.imgLeft.alt}/>
				</Col>
				<Col lg={4} className="order-sm-1 order-lg-0 px-lg-0">
					<ReferenceBlock text={aboutRef} author={aboutRefAuthor}/>
				</Col>
				<Col sm={6} lg={4} className="pl-sm-0">
					<img className="img-fluid"src={aboutRefImgs.imgRight.sizes.medium_large} alt={aboutRefImgs.imgRight.alt}/>
				</Col>
			</Row>
		</Container>
	</section>
));
