import React from 'react';
import { Formik, } from 'formik';
import { Form, Button, Row, Col } from 'react-bootstrap';
import * as yup from 'yup';

// const requiredError = 'Toto pole je povinné.';


interface IPayFormProps {
	submit: (nextStep: number) => void;
	saveFormData(values: any): void;
	allowDeposit: boolean;
	allowBail: boolean;
}

const PAY_TYPE_TRANSFER_ID = 2;
const PAY_TYPE_CASH_ID = 1;
const PAY_TYPE_CARD_ID = 8;

export const paymentTypes = {
	1: 'Platba hotově',
	2: 'Převodním příkazem předem',
	8: 'Platební kartou na pobočce Valašské Meziříčí',
};



export default function PayForm(props: IPayFormProps) {
	const requiredError = 'Toto pole je povinné.';

	let rules = {
		// payTypeId: yup.number().required(requiredError),
	};

	if (props.allowBail) {
		rules['payTypeBailId'] = yup.number().required(requiredError);
	}

	if (props.allowDeposit) {
		rules['payTypeDepositId'] = yup.number().required(requiredError);
	}


	const schema = yup.object(rules);



	const initialValues = {
		// payTypeId: PAY_TYPE_TRANSFER_ID,
		payTypeDepositId: props.allowDeposit ? PAY_TYPE_TRANSFER_ID : null,
		payTypeBailId: props.allowBail ? PAY_TYPE_TRANSFER_ID : null
	};

	return (
		<Formik
			validationSchema={schema}
			onSubmit={(values, actions) => {
				actions.setSubmitting(false);
				props.saveFormData(values);
				props.submit(6);
			}}
			initialValues={initialValues}
		>
		{({
			handleSubmit,
			values,
			isSubmitting,
			setFieldValue
		}) => (
			<Form noValidate onSubmit={handleSubmit}>
				<Row>
					<Col sm="12" xl="4">
						<Form.Group as="fieldset" controlId="payFormDeposit" disabled={!props.allowDeposit}>
							<legend className="form-label">Platba zálohy</legend>
							<Form.Check
								className="p-0 mx-4"
								type="radio"
								id={'depositTransfer'}
								name="deposit"
								label={'Převodním příkazem předem'}
								checked={values.payTypeDepositId === PAY_TYPE_TRANSFER_ID}
								onChange={() => {
									setFieldValue('payTypeDepositId', PAY_TYPE_TRANSFER_ID)
								}}
								custom
							/>
							<Form.Check
								className="p-0 mx-4"
								type="radio"
								id={'depositCard'}
								name="deposit"
								label={'Platební kartou na pobočce Valašské Meziříčí'}
								checked={values.payTypeDepositId === PAY_TYPE_CARD_ID}
								onChange={() => {
									setFieldValue('payTypeDepositId', PAY_TYPE_CARD_ID)
								}}
								custom
							/>
						</Form.Group>
					</Col>
					<Col sm="12" xl="4">
						<Form.Group as="fieldset" controlId="payFormBail" disabled={!props.allowBail}>
							<legend className="form-label">Platba kauce</legend>
							<Form.Check
								className="p-0 mx-4"
								type="radio"
								id={'bailTransfer'}
								name="bail"
								label={'Převodním příkazem předem'}
								checked={values.payTypeBailId === PAY_TYPE_TRANSFER_ID}
								onChange={() => {
									setFieldValue('payTypeBailId', PAY_TYPE_TRANSFER_ID)
								}}
								custom
							/>
							<Form.Check
								className="p-0 mx-4"
								type="radio"
								id={'bailCard'}
								name="bail"
								label={'Platební kartou při převzetí vozidla'}
								checked={values.payTypeBailId === PAY_TYPE_CARD_ID}
								onChange={() => {
									setFieldValue('payTypeBailId', PAY_TYPE_CARD_ID)
								}}
								custom
							/>
						</Form.Group>
					</Col>
					{/*<Col sm="12" xl="4">*/}
					{/*	<Form.Group as="fieldset" controlId="payFormRent">*/}
					{/*		<legend className="form-label">Platba nájmu</legend>*/}
					{/*		<Form.Check*/}
					{/*			className="p-0 mx-4"*/}
					{/*			type="radio"*/}
					{/*			id={'rentTransfer'}*/}
					{/*			name="rent"*/}
					{/*			label={'Převodním příkazem předem'}*/}
					{/*			checked={values.payTypeId === PAY_TYPE_TRANSFER_ID}*/}
					{/*			onChange={() => {*/}
					{/*				setFieldValue('payTypeId', PAY_TYPE_TRANSFER_ID)*/}
					{/*			}}*/}
					{/*			custom*/}
					{/*		/>*/}
					{/*		<Form.Check*/}
					{/*			className="p-0 mx-4"*/}
					{/*			type="radio"*/}
					{/*			id={'rentCard'}*/}
					{/*			name="rent"*/}
					{/*			label={'Platební kartou při převzetí vozidla'}*/}
					{/*			checked={values.payTypeId === PAY_TYPE_CARD_ID}*/}
					{/*			onChange={() => {*/}
					{/*				setFieldValue('payTypeId', PAY_TYPE_CARD_ID)*/}
					{/*			}}*/}
					{/*			custom*/}
					{/*		/>*/}
					{/*	</Form.Group>*/}
					{/*</Col>*/}
				</Row>
				<Row>
					<Col sm="12" className="text-right">
						<Button disabled={isSubmitting} className="mt-4 mx-auto" type="submit">Pokračovat</Button>
					</Col>
				</Row>
			</Form>
		)}
	</Formik>
	);
}
