import React from 'react';
import withContent from '../../hoc/Content';
import WpContent from '../../hoc/loaders/Wp';
import { Container, Row, Col } from 'react-bootstrap';
import Heading from '../../components/Heading/Heading';
import SimpleText from '../../components/SimpleText/SimpleText';
import ContactForm from '../../components/ContactForm/ContactForm';

export default withContent({ content: WpContent.ACF_CONTACT_FORM })(({content: {
	ctfHeading,
	ctfSubheading,
	ctfText
}}) => {
	return (
		<section className="my-5">
			<Container>
				<Row>
					<Col className="text-center">
						<Heading title={ctfHeading} subtitle={ctfSubheading}/>
						<SimpleText className="text-light mt-4" content={ctfText}/>
					</Col>
				</Row>
				<Row>
					<Col>
						<ContactForm />
					</Col>
				</Row>
			</Container>
		</section>
	);
});
