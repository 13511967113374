import React from "react";
import styles from './MiniLoader.module.scss';
import classNames from "../../utils/ClassNames";
import MiniDots from "./MiniDots";

interface Props {
	show: boolean;
}

const MiniLoader = (props: Props) => {
	const {show} = props;

	const classes = classNames(
		styles.miniLoader,
		show ? styles.miniLoaderShow : '',
	);

	return (
		<div className={classes}>
			{show &&
				<>
					<span className="pr-2">Načítám</span>
					<MiniDots/>
				</>
			}
		</div>
	);
};

export default MiniLoader;
