import React from 'react'
import withContent from '../../hoc/Content';
import WpContent from '../../hoc/loaders/Wp';
import { Container, Row, Col } from 'react-bootstrap';
import YoutubeBanner from '../../components/YoutubeBanner/YoutubeBanner';
import SimpleText from '../../components/SimpleText/SimpleText';

export default withContent({ content: WpContent.ACF_YOUTUBE_BANNER })(({ content: {
	homeBannerText,
	homeBannerVideo,
	homeBannerAfter
}}) => {
	return (
		<section>
			<Container className="section-shadow">
				<Row>
					<Col>
						<YoutubeBanner videoSrc={homeBannerVideo} text={homeBannerText}/>
						{/*<SimpleText className="text-center my-3" content={homeBannerAfter}/>*/}
					</Col>
				</Row>
			</Container>
		</section>
	);
});
