const blue =  "#0099cc";
const darkBlue =  "#003366";
const light = '#dee2e6';
export default {
		// The wrapper (span)
		wrap: {
			position: 'relative',
			display: 'inline-block'
		},

		'wrap.hasFormControl': {
				display: 'block'
		},

		// The increase button arrow (i)
		arrowUp: {
				position: 'absolute',
				top: '50%',
				left: '50%',
				width: 0,
				height: 0,
				borderWidth: '0 0.6ex 0.6ex 0.6ex',
				borderColor: 'transparent transparent white',
				borderStyle: 'solid',
				margin: '-0.3ex 0 0 -0.56ex'
		},

		// The decrease button arrow (i)
		arrowDown: {
				position: 'absolute',
				top: '50%',
				left: '50%',
				width: 0,
				height: 0,
				borderWidth: '0.6ex 0.6ex 0 0.6ex',
				borderColor: 'white transparent transparent',
				borderStyle: 'solid',
				margin: '-0.3ex 0 0 -0.56ex'
		},

		// The vertical segment of the plus sign (for mobile only)
		plus: {
				position: 'absolute',
				top: '50%',
				left: '50%',
				width: 2,
				height: 10,
				background: 'white',
				margin: '-5px 0 0 -1px'
		},

		// The horizontal segment of the plus/minus signs (for mobile only)
		minus: {
				position: 'absolute',
				top: '50%',
				left: '50%',
				width: 10,
				height: 2,
				background: 'white',
				margin: '-1px 0 0 -5px'
		},

		// Common styles for the up/down buttons (b)
		btn: {
				position: 'absolute',
				right: 0,
				width: '3.53ex',
				borderColor: blue,
				borderStyle: 'solid',
				textAlign: 'center',
				cursor: 'default',
				transition: 'all 0.1s',
				background: blue,
				boxShadow: 'none'
		},

		btnUp: {
				top: 0,
				bottom: '50%',
				borderRadius: '0',
				borderWidth: '1px 1px 0 1px'
		},

		'btnUp.mobile': {
				width: '3.3ex',
				bottom: 0,
				boxShadow: 'none',
				borderRadius: 0,
				borderWidth: 1
		},

		btnDown: {
				top: '50%',
				bottom: 0,
				borderRadius: '0',
				borderWidth: '0 1px 1px 1px'
		},

		'btnDown.mobile': {
				width: '3.3ex',
				bottom: 0,
				left: 0,
				top: 0,
				right: 'auto',
				boxShadow: 'none',
				borderRadius: 0,
				borderWidth: 1
		},

		'btn:hover': {
				background: blue,
				cursor: 'pointer'
		},

		'btn:active': {
				background: darkBlue,
				boxShadow: 'none'
		},

		'btn:disabled': {
				opacity: 0.5,
				boxShadow: 'none',
				cursor: 'not-allowed'
		},

		// The input (input[type="text"])
		input: {
				paddingTop: '.65ex',
				paddingBottom: '.65ex',
				paddingRight: '3.6ex',
				boxSizing: 'border-box',
				fontSize: 'inherit'
		},

		// The input with bootstrap class
		'input:not(.form-control)': {
				border: `1px solid ${light}`,
				borderRadius: 0,
				paddingLeft: 4,
				display: 'block',
				WebkitAppearance: 'none',
				lineHeight: 'normal'
		},

		'input.mobile': {
				paddingLeft: ' 3.4ex',
				paddingRight: '3.4ex',
				textAlign: 'center'
		},

		'input:focus': {
			outline: 'none'
		},

		'input:disabled': {
				color: 'rgba(0, 0, 0, 0.3)',
				textShadow: '0 1px 0 rgba(255, 255, 255, 0.8)'
		}
	}
