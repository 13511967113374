import React from 'react';
import withContent from '../../hoc/Content';
import WpContent from '../../hoc/loaders/Wp';
import styles from './Footer.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../../components/Logo/Logo';
import SimpleText from '../../components/SimpleText/SimpleText';
import Socials from '../../components/Socials/Socials';
// import NewsletterSub from '../../components/NewsletterSub/NewsletterSub';

export default withContent({ content: WpContent.ACF_FOOTER })(({ content: {
	address,
	usefulLinks
}}) => (
	<footer id="footer" className="bg-darkBlue">
			<Container className="py-5">
				<Row className="text-center text-lg-left justify-content-center my-xl-5">
					<Col sm={6}>
						<Logo />
						<SimpleText className="text-white mt-3 list-unstyled" content={address}/>
						<Socials />
					</Col>
					<Col sm={6} className="mt-5 mt-sm-1 col-xxl-4">
						<SimpleText className={styles.usefulLinks} content={usefulLinks}/>
					</Col>
					{/*<Col md={8} lg={5} className="mt-3 mt-lg-0 col-xxl-4">
						<NewsletterSub />
					</Col>*/}
				</Row>
			</Container>
	</footer>
));

