import React, {Component} from 'react';
import { Formik, } from 'formik';
import { Form, Button, Col } from 'react-bootstrap';
import * as yup from 'yup';
import compose from "../../hoc/composition";
import withContent from "../../hoc/Content";
import ApiContent from "../../hoc/loaders/Api";
import {Car, CarLocation, Country, RentOpeningHours, RentSettings} from "autodevelo-api-client-ts";
import {Moment} from "moment";
import {readHash} from "../../store/hash";

const requiredError = 'Vyberte prosím jednu z možností.';

const schema = yup.object({
  destinationCountryId: yup.number().min(0).required(requiredError),
  pickupPlace: yup.number().min(0).required(requiredError),
	returnPlace: yup.number().min(0).required(requiredError),
	pickupTimeHour: yup.string().required(requiredError),
	pickupTimeMinute: yup.string().required(requiredError),
	returnTimeHour: yup.string().required(requiredError),
	returnTimeMinute: yup.string().required(requiredError),
	travelersCount: yup.number().min(1).required(requiredError),
	freeParking: yup.string().required(requiredError),
});

interface IRentInfoFormContent {
	rentSettings: RentSettings;
}

interface RentInfoFormProps {
	countries: Array<Country>;
	rentSettings: RentSettings;
	rentFrom: Moment;
	rentTo: Moment;
	car: Car;
	content: IRentInfoFormContent;
	saveFormData: (values) => void;
	onChange: (values) => void;
	submit: (step) => void;
}

interface IRentInfoFormStatusState {
	openingHoursData: any;
}





class RentInfoForm extends Component<RentInfoFormProps, IRentInfoFormStatusState> {

	constructor(props, context) {
		super(props, context);

		this.state = {
			openingHoursData: this.getRentOpeningHours(this.props.rentFrom, this.props.rentTo, this.props.rentSettings.openingHours),
		};
	};


	getMinutes = (pickupHour: number, returnHour: number) => {
		return this.getRentOpeningHours(this.props.rentFrom, this.props.rentTo, this.props.rentSettings.openingHours, pickupHour, returnHour);
	};


	getRentOpeningHours(rentFrom: Moment, rentTo: Moment, openingHours: RentOpeningHours, pickupHour: number = null, returnHour: number = null) {
		const pickupDayName = rentFrom.locale('en').format('dddd').toLowerCase();
		const returnDayName = rentTo.locale('en').format('dddd').toLowerCase();

		// pickup
		let fromTimeParts = openingHours.rent[pickupDayName].from.split(':');
		let toTimeParts = openingHours.rent[pickupDayName].to.split(':');

		let hour;

		let hoursData = [];
		let pickupMinutes = [];


		if (!openingHours.rent[pickupDayName].closed) {
			hour = Number(fromTimeParts[0]);
			while (hour <= Number(toTimeParts[0])) {
				hoursData.push(hour);
				hour++;
			}

			if (!pickupHour && hoursData[0]) {
				pickupHour = hoursData[0];
			}

			pickupMinutes = this.prepareMinutes(pickupHour, fromTimeParts, toTimeParts);
		}


		// return
		fromTimeParts = openingHours.returnRent[returnDayName].from.split(':');
		toTimeParts = openingHours.returnRent[returnDayName].to.split(':');


		let returnHoursData = [];
		let returnMinutes = [];

		if (!openingHours.returnRent[returnDayName].closed) {
			hour = Number(fromTimeParts[0]);
			while (hour <= Number(toTimeParts[0])) {
				returnHoursData.push(hour);
				hour++;
			}

			if (!returnHour && returnHoursData[0]) {
				returnHour = returnHoursData[0];
			}

			returnMinutes = this.prepareMinutes(returnHour, fromTimeParts, toTimeParts);
		}



		return {
			rent: {
				hours: hoursData,
				minutes: pickupMinutes,
			},
			return: {
				hours: returnHoursData,
				minutes: returnMinutes,
			},
		}
	}


	protected prepareMinutes = (returnHour, fromTimeParts, timeParts) => {
		let returnMinutes = [0, 15, 30, 45];

		if (!!returnHour && Number(timeParts[0]) === returnHour) {
			let startMinutes = Number(timeParts[1]);


			let newReturnMinutes = [];
			returnMinutes.map((value, key) => {
				if (value <= startMinutes) {
					newReturnMinutes.push(value);
				}
        return value;
			});
			returnMinutes = newReturnMinutes;
		} else if (!!returnHour && Number(fromTimeParts[0]) === returnHour) {
			let startMinutes = Number(fromTimeParts[1]);

			let newReturnMinutes = [];
			returnMinutes.map((value, key) => {
				if (value >= startMinutes) {
					newReturnMinutes.push(value);
				}
        return value;
			});
			returnMinutes = newReturnMinutes;
		}
		return returnMinutes;
	};



	handleChangeTime = (values, setValues, e = null) => {
		setValues(e);
		values[e.target.name] = Number(e.target.value);

		let openingHours = this.getMinutes(Number(values.pickupTimeHour), Number(values.returnTimeHour));

		let data = {};
		data['pickupTimeHour'] = values['pickupTimeHour'];
		data['pickupTimeMinute'] = values['pickupTimeMinute'];
		data['returnTimeHour'] = values['returnTimeHour'];
		data['returnTimeMinute'] = values['returnTimeMinute'];
		data[e.target.name] = e.target.value;

		this.props.onChange(data);

		this.setState({
			openingHoursData: openingHours
		});
	};

	getTravelersCountOptions = () => {
		const {car} = this.props;
		let options = [];
		options.push({value: '', name: ''});

		for (let i = 1; i <= car.seats; i++) {
			options.push({value: i, name: i});
		}
		return options;
	};



	render = () => {
		const props = this.props;
		const openingHoursData = this.state.openingHoursData;

		// let locations = [];
		// locations.push(new CarLocation({'id': 1, 'name': 'Valašské Meziříčí'}));
		// locations.push(new CarLocation({'id': 2, 'name': 'Praha'}));
		// locations.push(new CarLocation({'id': 3, 'name': 'Jablonec nad Nisou'}));
		// locations.push(new CarLocation({'id': 4, 'name': 'Brno'}));
		// locations.push(new CarLocation({'id': 5, 'name': 'Uherské Hradiště'}));

		let pickupLocations = [], returnLocations = [];
		pickupLocations.push(this.props.car.location);
		returnLocations = pickupLocations;

		const urlData = readHash();

		return (
			<Formik
				validationSchema={schema}
				onSubmit={(values, actions) => {
					actions.setSubmitting(false);
					props.saveFormData(values);
					props.submit(1);
				}}
				initialValues={{
					pickupPlace: String(this.props.car.location.id),
					returnPlace: String(this.props.car.location.id),
					destinationCountryId: '',
					travelersCount: !!urlData['personsCount'] ? urlData['personsCount'] : '',
					freeParking: '',
					pickupTimeHour: '' + openingHoursData.rent.hours[0],
					pickupTimeMinute: '' + openingHoursData.rent.minutes[0],
					returnTimeHour: '' + openingHoursData.return.hours[0],
					returnTimeMinute: '' + openingHoursData.return.minutes[0]

				}}
			>
				{({
					  handleSubmit,
					  setValues,
					  handleChange,
					  values,
					  touched,
					  errors,
					  isSubmitting
				  }) => (
					<Form noValidate onSubmit={handleSubmit} id="rentInfoForm">
						<Form.Row>
							<Form.Group as={Col} sm={6} md={4} controlId="rentInfoFormPickupPlace">
								<Form.Label>Místo vyzvednutí vozu:</Form.Label>
								<Form.Control
									as="select"
									name="pickupPlace"
									value={values.pickupPlace}
									onChange={handleChange}
									isValid={touched.pickupPlace && !errors.pickupPlace}
									isInvalid={touched.pickupPlace && !!errors.pickupPlace}
								>
									<option value=""></option>
									{pickupLocations.map((location: CarLocation) => {
										return <option value={location.id}>{location.name}</option>;
									})}
								</Form.Control>
								<Form.Control.Feedback>Děkujeme!</Form.Control.Feedback>
								<Form.Control.Feedback type="invalid">
									{errors.pickupPlace}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} sm={6} md={4} controlId="rentInfoFormReturnPlace">
								<Form.Label>Místo vrácení vozu:</Form.Label>
								<Form.Control
									as="select"
									name="returnPlace"
									value={values.returnPlace}
									onChange={handleChange}
									isValid={touched.returnPlace && !errors.returnPlace}
									isInvalid={touched.returnPlace && !!errors.returnPlace}
								>
									<option value=""></option>
									{returnLocations.map((location: CarLocation) => {
										return <option value={location.id}>{location.name}</option>;
									})}
								</Form.Control>
								<Form.Control.Feedback>Děkujeme!</Form.Control.Feedback>
								<Form.Control.Feedback type="invalid">
									{errors.returnPlace}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} sm={6} md={4} controlId="rentInfoFormDestination">
								<Form.Label>Destinace:</Form.Label>
								<Form.Control
									as="select"
									name="destinationCountryId"
									value={values.destinationCountryId}
									onChange={handleChange}
									isValid={touched.destinationCountryId && !errors.destinationCountryId}
									isInvalid={touched.destinationCountryId && !!errors.destinationCountryId}
								>
									<option value=""></option>
									{props.rentSettings.allowedDestinations.map((country) => {
										return <option value={country.id}>{country.name}</option>;
									})}
								</Form.Control>
								<Form.Control.Feedback>Děkujeme!</Form.Control.Feedback>
								<Form.Control.Feedback type="invalid">
									{errors.destinationCountryId}
								</Form.Control.Feedback>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col} sm={6} md={4} controlId="rentInfoFormPickupTime" className="col-xxl-2">
								<Form.Label>Čas vyzvednutí vozu:</Form.Label>
								<div className="selectAsTimePicker">
									<Form.Control
										as="select"
										name="pickupTimeHour"
										value={values.pickupTimeHour}
										onChange={(e) => this.handleChangeTime(values, handleChange, e)}
									>
										{openingHoursData.rent.hours.map((hour) => {
											return <option value={hour}>{hour}</option>;
										})}
									</Form.Control>
									<Form.Control
										as="select"
										name="pickupTimeMinute"
										value={values.pickupTimeMinute}
										onChange={(e) => this.handleChangeTime(values, handleChange, e)}
									>
										{this.state.openingHoursData.rent.minutes.map((minutes) => {
											return (<option value={minutes}>{minutes}</option>);
										})}
									</Form.Control>
								</div>
							</Form.Group>
							<Form.Group as={Col} sm={6} md={4} controlId="rentInfoFormReturnTime" className="col-xxl-2">
								<Form.Label>Čas vrácení vozu:</Form.Label>
								<div className="selectAsTimePicker">
									<Form.Control
										as="select"
										name="returnTimeHour"
										value={values.returnTimeHour}
										onChange={(e) => this.handleChangeTime(values, handleChange, e)}
									>
										{openingHoursData.return.hours.map((hour) => {
											return <option value={hour}>{hour}</option>;
										})}
									</Form.Control>
									<Form.Control
										as="select"
										name="returnTimeMinute"
										value={values.returnTimeMinute}
										onChange={(e) => this.handleChangeTime(values, handleChange, e)}
									>
										{this.state.openingHoursData.return.minutes.map((minutes) => {
											return (<option value={minutes}>{minutes}</option>);
										})}
									</Form.Control>
								</div>
							</Form.Group>
							<Form.Group as={Col} sm={6} md={4} controlId="rentInfoFormPassangersCount" className="col-xxl-2">
								<Form.Label>Počet cestujících:</Form.Label>
								<Form.Control
									as="select"
									name="travelersCount"
									value={values.travelersCount}
									onChange={handleChange}
									isValid={touched.travelersCount && !errors.travelersCount}
									isInvalid={touched.travelersCount && !!errors.travelersCount}
								>
									{this.getTravelersCountOptions().map(option => {
										return (
											<option value={option.value}>{option.name}</option>
										);
									})}
								</Form.Control>
								<Form.Control.Feedback>Děkujeme!</Form.Control.Feedback>
								<Form.Control.Feedback type="invalid">
									{errors.travelersCount}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} sm={12} md={6} controlId="rentInfoFormFreeParking" className="col-xxl-4">
								<Form.Label>Bezplatné parkování v místě převzetí?</Form.Label>
								<Form.Control
									as="select"
									name="freeParking"
									value={values.freeParking}
									onChange={handleChange}
									isValid={touched.freeParking && !errors.freeParking}
									isInvalid={touched.freeParking && !!errors.freeParking}
								>
									<option value=""></option>
									<option value="true">Ano</option>
									<option value="false">Ne</option>
								</Form.Control>
								<Form.Control.Feedback>Děkujeme!</Form.Control.Feedback>
								<Form.Control.Feedback type="invalid">
									{errors.freeParking}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md={6} className="text-md-right mt-md-3 col-xxl-2">
								<Button disabled={isSubmitting} className="mt-4 mx-auto" type="submit">Pokračovat</Button>
							</Form.Group>
						</Form.Row>
					</Form>
				)}
			</Formik>
		);

	}
}


export default compose(
	withContent({
		content: ApiContent({
			fetch: async (client, props)  => {
				const rentSettings = await client.readRentSettings();

				return {
					rentSettings: rentSettings,
				};
			},
			cache: false
		})
	}),
)(RentInfoForm);
