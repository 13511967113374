import axios from 'axios';
import { Loader, LoaderBaseOptions } from './base';

const instance = axios.create({
	baseURL: 'https://wp.dsf.cz/wp-json/'
});

type WpContentOptions = LoaderBaseOptions & {
	path: string
}

export default function WpContent(options: WpContentOptions) {
	const { path } = options
	return Loader(async () => (await instance.get(path)).data, options);
}

WpContent.MENU_TOP_NAVBAR = WpContent({
	path: 'wp-api-menus/v2/menus/2',
//	path: 'menus/v1/menus/menu-1',
	dest: [ 'items' ]
});

WpContent.ACF_HOME_HERO = WpContent({
	path: 'acf/v3/pages/7',
	dest: [ 'acf' ]
});

WpContent.ACF_ABOUT = WpContent({
	path: 'acf/v3/pages/7',
	dest: [ 'acf' ]
});

WpContent.ACF_HOW_IT_WORKS = WpContent({
	path: 'acf/v3/pages/7',
	dest: [ 'acf' ]
});

WpContent.ACF_YOUTUBE_BANNER = WpContent({
	path: 'acf/v3/pages/7',
	dest: [ 'acf' ]
});

WpContent.RENT_KEY_INFORMATION = WpContent({
	path: 'acf/v3/pages/192',
	dest: [ 'acf' ]
});

WpContent.ACF_ADVANTAGES_SECTION = WpContent({
	path: 'acf/v3/pages/7',
	dest: [ 'acf' ]
});

WpContent.ACF_CONTACT_SECTION = WpContent({
	path: 'acf/v3/pages/7',
	dest: [ 'acf' ]
});

WpContent.ACF_FOOTER = WpContent({
	path: 'acf/v3/options/options',
	dest: [ 'acf' ]
});

WpContent.ACF_SOCIALS = WpContent({
	path: 'acf/v3/options/options/socials',
	dest: [ 'socials' ]
});

WpContent.ACF_CONTACT_FORM = WpContent({
	path: 'acf/v3/options/options',
	dest: [ 'acf' ]
});
