import axios from 'axios';
import {CarList} from "autodevelo-api-client-ts/dist/client/entity/CarList";
import {RentCarType} from "autodevelo-api-client-ts/dist/client/entity/RentCarType";
import {
	Car,
	CarLocation,
	Country,
	Equipment, RentOfferedService,
	RentReservation,
	RentSettings,
	ReservationServiceInput
} from "autodevelo-api-client-ts";

interface Cache {
	rentSettings?: RentSettings;
	allCountries?: Array<Country>;
	availableCarLocations?: Array<CarLocation>;
	availableEquipments?: Array<Equipment>;
}

export default class BackendClient {

	protected cache: Cache = {};

	public static getApiUrl(): string {
		return process.env.REACT_APP_URL;
	}

	public static getBaseUrl(): string {
		return process.env.REACT_APP_BASE_URL;
	}

	async readReservation(id: number, from: string, to: string) {
		return new Promise((resolve, reject) => {
			return axios.get(BackendClient.getApiUrl() + '/read-reservation', {
				params: {
					id: id,
					rentFrom: from,
					rentTo: to
				}
			}).then((data) => {
				if (data['data']['errors'] !== undefined) {
					reject(data['errors']);
				}
				resolve(new RentReservation(data['data']));
			}).catch((err) => {
				reject(err);
			})
		});
	}


	async getCompanyDataFromAres(ic: string) {
		return axios.get(BackendClient.getApiUrl() + '/ares', {
			params: {
				ico: ic
			}
		});
	}


	async readRentSettings(): Promise<RentSettings> {
		return new Promise((resolve, reject) => {
			const {cache} = this;
			if (cache.rentSettings) {
				resolve(cache.rentSettings);
				return;
			}

			axios.get(BackendClient.getApiUrl() + '/read-rent-settings').then((data) => {
				if (data['data']['errors'] !== undefined) {
					reject(data['errors']);
				}
				const rentSettings = new RentSettings(data['data']);
				cache.rentSettings = rentSettings;
				resolve(rentSettings);
			}).catch((err) => {
				reject(err);
			})
		});
	}


	async getAllCountries(): Promise<Array<Country>> {
		return new Promise((resolve, reject) => {
			const {cache} = this;
			if (cache.allCountries) {
				resolve(cache.allCountries);
				return;
			}

			axios.get(BackendClient.getApiUrl() + '/get-all-countries').then((data) => {
				if (data['data']['errors'] !== undefined) {
					reject(data['errors']);
				}

				let countries = [];
				data['data'].map((data) => {
					countries.push(new Country(data));
					return data;
				});
				cache.allCountries = countries;
				resolve(countries)
			}).catch((err) => {
				reject(err);
			})
		});
	}

	async readCarUnconfirmedReservations(carId: number, rentFrom: Date, rentTo: Date) {
		return new Promise((resolve, reject) => {
			axios.get(BackendClient.getApiUrl() + '/read-car-unconfirmed-reservations', {
				params: {
					carId: carId,
					rentFrom: this.dateToString(rentFrom),
					rentTo: this.dateToString(rentTo),
				}
			}).then((response) => {
				if (response['errors'] !== undefined) {
					reject(response['errors']);
				}

				let reservations = [];
				response['data'].map((data) => {
					reservations.push(new RentReservation(data));
					return data;
				});

				resolve(reservations);
			}).catch((err) => {
				reject(err);
			})
		});
	}


	async readCarConfirmedReservations(carId: number, rentFrom: Date, rentTo: Date) {
		return new Promise((resolve, reject) => {
			axios.get(BackendClient.getApiUrl() + '/read-car-confirmed-reservations', {
				params: {
					carId: carId,
					rentFrom: this.dateToString(rentFrom),
					rentTo: this.dateToString(rentTo),
				}
			}).then((response) => {
				let reservations = [];
				response['data'].map((data) => {
					reservations.push(new RentReservation(data));
					return data;
				});
				resolve(reservations);
			}).catch((err) => {
				reject(err);
			})
		});
	}


	async readCarDetail(id: number, rentFrom: Date, rentTo: Date) {
		return new Promise((resolve, reject) => {
			axios.get(BackendClient.getApiUrl() + '/read-rent-car-detail', {
				params: {
					id: id,
					from: this.dateToString(rentFrom),
					to: this.dateToString(rentTo),
				}
			}).then((response) => {
				resolve(new Car(response['data']));
			}).catch((err) => {
				reject(err);
			})
		});
	}



	async readAvailableCarEquipments(): Promise<Array<Equipment>> {
		return new Promise((resolve, reject) => {
			const {cache} = this;
			if (cache.availableEquipments) {
				resolve(cache.availableEquipments);
				return;
			}

			axios.get(BackendClient.getApiUrl() + '/read-available-car-equipments').then((data) => {
				let equipments = [];
				if (data.data.length > 0) {
					data['data'].map((data) => {
						equipments.push(new Equipment(data));
						return data;
					});
				}
				cache.availableEquipments = equipments;
				resolve(equipments)
			}).catch((err) => {
				reject(err);
			})
		});
	}



	async readAvailableCarLocations(): Promise<Array<CarLocation>> {
		return new Promise((resolve, reject) => {
			const {cache} = this;
			if (cache.availableCarLocations) {
				resolve(cache.availableCarLocations);
				return;
			}

			axios.get(BackendClient.getApiUrl() + '/read-available-car-locations').then((data) => {
				if (data['data']['errors'] !== undefined) {
					reject(data['errors']);
				}

				let locations = [];
				if (data.data.length > 0) {
					data['data'].map((data) => {
						locations.push(new CarLocation(data));
						return data;
					});
				}
				cache.availableCarLocations = locations;
				resolve(locations)
			}).catch((err) => {
				reject(err);
			})
		});
	}


	async createReservation(
		carId: number,
		services: Array<ReservationServiceInput>,
		destinationCountryId: number,
		locationRentId: number,
		locationReturnId: number,
		travelersCount: number,
		freeParking: boolean,
		rentFrom: Date,
		rentTo: Date,
		clientCountryId: number,
		clientCompany: string,
		clientName: string,
		clientSurname: string,
		clientStreet: string,
		clientNumber: string,
		clientCity: string,
		clientZip: string,
		clientEmail: string,
		clientPhone: string,
		payTypeId: number,
		payTypeDepositId: number,
		payTypeBailId: number,
		isCarDelivery: boolean,
		isCarTakeover: boolean,
		clientIc?: string,
		note?: string,
		clientNameDelivery?: string,
		clientStreetDelivery?: string,
		clientCityDelivery?: string,
		clientZipDelivery?: string
	) {
		return new Promise((resolve, reject) => {

			let data = {
				carId: carId,
				services: services,
				destinationCountryId: destinationCountryId,
				idLocationRent: locationRentId,
				idLocationReturn: locationReturnId,
				travelersCount: travelersCount,
				freeParking: freeParking,
				rentFrom: this.dateToString(rentFrom),
				rentTo: this.dateToString(rentTo),
				idClientCountry: clientCountryId,
				clientCompany: clientCompany,
				clientName: clientName,
				clientSurname: clientSurname,
				clientStreet: clientStreet,
				clientNumber: clientNumber,
				clientCity: clientCity,
				clientZip: clientZip,
				clientEmail: clientEmail,
				clientPhone: clientPhone,
				idPayType: payTypeId,
				idPayTypeDeposit: payTypeDepositId,
				idPayTypeBail: payTypeBailId,
				isCarDelivery: isCarDelivery,
				isCarTakeover: isCarTakeover,
				clientIc: clientIc,
				note: note,
				clientNameDelivery: clientNameDelivery,
				clientStreetDelivery: clientStreetDelivery,
				clientCityDelivery: clientCityDelivery,
				clientZipDelivery: clientZipDelivery
			};
			axios.post(BackendClient.getApiUrl() + '/create-reservation', data,
			).then((data) => {
				if (data['data']['errors'] !== undefined) {
					reject(data['errors']);
				}

				resolve(new RentReservation(data['data']));
			}).catch((err) => {
				reject(err);
			})
		});
	}


	async readAvailableRentCarTypes(): Promise<Array<RentCarType>> {
		return new Promise((resolve, reject) => {
			axios.get(BackendClient.getApiUrl() + '/read-available-car-types').then((data) => {
				if (data['data']['errors'] !== undefined) {
					reject(data['errors']);
				}

				let rentCarTypes = [];
				if (data.data.length > 0) {
					data['data'].map((typeData) => {
						rentCarTypes.push(new RentCarType(typeData));
						return typeData;
					});
				}
				resolve(rentCarTypes)
			}).catch((err) => {
				reject(err);
			})
		});
	}


	async readAvailableRentCar(
		from: Date,
		to: Date,
		limit?: number,
		offset?: number,
		seats?: number,
		types?: Array<string>,
		equipmentIds?: Array<number>,
		locationId?: number,
		gearbox?: string
	): Promise<CarList> {

		let args = {};

		if (from !== undefined) {
			args['from'] = this.dateToString(from);
		}

		if (to !== undefined) {
			args['to'] = this.dateToString(to);
		}

		if (limit !== undefined) {
			args['limit'] = limit;
		}

		if (offset !== undefined) {
			args['offset'] = offset;
		}

		if (seats !== undefined) {
			args['seats'] = seats;
		}

		if (types !== undefined && types.length > 0) {
			args['type'] = types;
		}

		if (equipmentIds !== undefined && equipmentIds.length > 0) {
			args['equipmentIds'] = equipmentIds;
		}

		if (locationId !== undefined) {
			args['locationId'] = locationId;
		}

		if (gearbox !== undefined) {
			args['gearbox'] = gearbox;
		}


		return axios.get(BackendClient.getApiUrl() + '/read-available-cars', {
			params: args
		}).then(function (response) {
			let cars = [];
			response.data['cars'].map((data) => {
				cars.push(new Car(data));
				return data;
			});

			return new CarList(response.data['totalCount'], cars);
		});
	}



	protected dateToString(value: Date): string {
		let month = String(value.getMonth() + 1);
		let day = String(value.getDate());
		let hours = String(value.getHours());
		let minutes = String(value.getMinutes());

		if (month.length === 1) {
			month = "0" + month;
		}

		if (day.length === 1) {
			day = "0" + day;
		}

		if (hours.length === 1) {
			hours = "0" + hours;
		}

		if (minutes.length === 1) {
			minutes = "0" + minutes;
		}
		return value.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
	}



	async readRentOfferedServices() {
		return new Promise((resolve, reject) => {
			return axios.get(BackendClient.getApiUrl() + '/read-offered-services', {}).then((data) => {
				if (data['data']['errors'] !== undefined) {
					reject(data['errors']);
				}

				let services = [];
				data['data'].map((data) => {
					services.push(new RentOfferedService(data));
					return data;
				});
				resolve(services)

				resolve(services);
			}).catch((err) => {
				reject(err);
			})
		});
	}



}
