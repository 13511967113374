import React from 'react';
import { Formik, } from 'formik';
import { Form, Button, Col } from 'react-bootstrap';
import * as yup from 'yup';

const phoneRegExp = /^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/;
const requiredError = 'Toto pole je povinné.';

const schema = yup.object({
  firstName: yup.string().required(requiredError),
	lastName: yup.string().required(requiredError),
	email: yup.string().email('E-mailová adresa je ve špatném formátu.').required(requiredError),
	phone: yup.string().matches(phoneRegExp, 'Telefonní číslo je ve špatném formátu.').required(requiredError),
	textarea: yup.string().required(requiredError)
});

export default function ContactForm() {
	return (
		<Formik
		validationSchema={schema}
		onSubmit={(values, actions) => {
			setTimeout(() => {
				alert(JSON.stringify(values, null, 2));
				actions.setSubmitting(false);
				actions.resetForm();
			}, 2000);
		}}
		initialValues={{
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			textarea: ''
		}}
	>
		{({
			handleSubmit,
			handleChange,
			values,
			touched,
			errors,
			isSubmitting
		}) => (
			<Form noValidate onSubmit={handleSubmit}>
				<Form.Row>
					<Form.Group as={Col} sm="6" xl="3" controlId="ctfFirstName">
						<Form.Label>Jméno</Form.Label>
						<Form.Control
							type="text"
							name="firstName"
							value={values.firstName}
							onChange={handleChange}
							isValid={touched.firstName && !errors.firstName}
							isInvalid={touched.firstName && !!errors.firstName}
						/>
						<Form.Control.Feedback>Správně!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
							{errors.firstName}
            </Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Col} sm="6" xl="3" controlId="ctfLastName">
						<Form.Label>Příjmení</Form.Label>
						<Form.Control
							type="text"
							name="lastName"
							value={values.lastName}
							onChange={handleChange}
							isValid={touched.lastName && !errors.lastName}
							isInvalid={touched.lastName && !!errors.lastName}
						/>
						<Form.Control.Feedback>Správně!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
							{errors.lastName}
            </Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Col} sm="6" xl="3" controlId="ctfEmail">
						<Form.Label>Email</Form.Label>
						<Form.Control
							type="email"
							name="email"
							value={values.email}
							onChange={handleChange}
							isValid={touched.email && !errors.email}
							isInvalid={touched.email && !!errors.email}
						/>
						<Form.Control.Feedback>Správně!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
							{errors.email}
            </Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Col} sm="6" xl="3" controlId="ctfPhone">
						<Form.Label>Telefon</Form.Label>
						<Form.Control
							type="phone"
							name="phone"
							value={values.phone}
							onChange={handleChange}
							isValid={touched.phone && !errors.phone}
							isInvalid={touched.phone && !!errors.phone}
						/>
						<Form.Control.Feedback>Správně!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row className="text-center">
					<Form.Group as={Col} controlId="ctfTextarea">
						<Form.Control
							as="textarea"
							name="textarea"
							rows="6"
							value={values.textarea}
							onChange={handleChange}
							isValid={touched.textarea && !errors.textarea}
							isInvalid={touched.textarea && !!errors.textarea}
						/>
						<Form.Control.Feedback>Děkujeme!</Form.Control.Feedback>
						<Form.Control.Feedback type="invalid">
							{errors.textarea}
            </Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
				<Form.Row>
					<Button disabled={isSubmitting} className="mt-4 mx-auto" type="submit">Odeslat</Button>
				</Form.Row>
			</Form>
		)}
	</Formik>
	);
}
