import React from 'react';
import withContent from '../../hoc/Content';
import WpContent from '../../hoc/loaders/Wp';

export default withContent({ content: WpContent.ACF_SOCIALS })(({ content }) => {
	
	if (!Array.isArray(content)) {
		console.error('missing content acf socials');
		return null;
	}
	
	return (
	<div>
		{content.map(social => (
			<a key={social.icon.id} href={social.link.url} target="_blank" rel="noopener noreferrer">
				<img className="mr-2" alt="icon" src={social.icon.url} height={17} />
			</a>
		))}
	</div>
)});

