import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';

declare global {
	interface Window { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any; }
}
// import axios from 'axios';

// axios.defaults.baseURL = 'http://doorental.test/admin/wp-json/';

// axios.interceptors.request.use(request => {
// 	return request;
// }, error => {
// 	console.log(error);
// 	return Promise.reject(error);
// });

// axios.interceptors.response.use(response => {
// 	return response;
// }, error => {
// 	console.log(error);
// 	return Promise.reject(error);
// });

const logger = stroe => {
	return next => {
		return action => {
			// console.log('[Middleware] Dispatching', action);
			const result = next(action);
			// console.log('[Middleware] next state', stroe.getState());
			return result;
		}
	}
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(logger)));

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
