import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ApiContent from '../../hoc/loaders/Api';
import { IAppState } from '../../store/reducer';
import { setFormData } from '../../store/actions';
import { readHash, writeHash } from '../../store/hash';
import ResultsSection from './ResultsSection';

interface IResultsSectionWrapperState {
	activePage: number;
}

const LIMIT = 10;

export class ResultsSectionWrapper extends PureComponent<{}, IResultsSectionWrapperState> {
	constructor(props, context) {
		super(props, context);

		const hash = readHash();
		this.state = {
			activePage : !!hash['page'] ? hash['page'] : 1
		};
	}

	handlePageChange = activePage => {
		const queryParams = readHash();
		queryParams.page = activePage;

		writeHash(queryParams);
		this.setState({ activePage });
	};

	render() {
		const content = ApiContent({
			cache: false,
			fetch: async(client, props) => {
				let data = props.formData;
				if (!data) {
					data = readHash();
				}

				return {
					carResult: await client.readAvailableRentCar(
						data.startDate ? new Date(data.startDate) : undefined,
						data.endDate ? new Date(data.endDate) : undefined,
						LIMIT,
						(this.state.activePage * LIMIT) - LIMIT,
						data.personsCount,
						data.checkboxGroup,
						data.vehicleEquipment.map(value => value.value),
						data.vehicleLocation,
						data.gearbox
					),
					rentOfferedServices: await client.readRentOfferedServices(),
					rentSettings: await client.readRentSettings(),
				};
			}
		});

		return <ResultsSection
			content={content}
			activePage={this.state.activePage}
			handlePageChange={this.handlePageChange}
			itemsCountPerPage={LIMIT}
		/>;
	}
}

export default connect(
	({ formData }: IAppState) => ({ formData }),
	{ setFormData }
)(ResultsSectionWrapper);
