import React from 'react';
import { readHash } from '../../store/hash';
import withContent, { IWithContent } from '../../hoc/Content';
import ApiContent from '../../hoc/loaders/Api';
import compose from '../../hoc/composition';
import { connect } from 'react-redux';
import { IAppState } from '../../store/reducer';
import DetailHero from '../DetailHero/DetailHero';
import DetailMainSection from '../DetailMainSection/DetailMainSection';
import DocumentTitle from "react-document-title";
import WpContent from "../../hoc/loaders/Wp";
import CarHelper from "../../utils/CarHelper";

function VehicleDetailPage(props: IWithContent) {
	const title = CarHelper.printTitle(props.content[0]);
	return (
		<DocumentTitle title={ title + ' | Dovolená s Fordem'}>
			<>
				<DetailHero {...props}/>
				<DetailMainSection {...props} />
			</>
		</DocumentTitle>
	);
}

export default compose(
	withContent({
		content: ApiContent({
			fetch: (client, props) => {

				let fromDate = new Date();
				fromDate.setDate(1);
				fromDate.setHours(0, 0, 0);


				let toDate = new Date();
				toDate.setDate(1);
				toDate.setHours(0, 0, 0);
				toDate.setMonth(toDate.getMonth() + 18);

				const hashData = readHash();


				return Promise.all([
					client.readCarDetail(hashData['carId'], new Date(hashData['startDate']), new Date(hashData['endDate'])),
					client.readCarConfirmedReservations(hashData['carId'], fromDate, toDate),
					client.readCarUnconfirmedReservations(hashData['carId'], fromDate, toDate),
					WpContent.RENT_KEY_INFORMATION(props),
					client.readRentOfferedServices(),
					client.readRentSettings(),
				]);
			},
			cache: false
		})
	}),
	connect((state: IAppState) => ({formData: state.formData, vehicleEquipmentList: Object.assign({}, state.reservationFormData['services'])})),
)(VehicleDetailPage);
