import {Component, default as React} from "react";
import {Car} from "autodevelo-api-client-ts";
import {resolveCarImage} from "../../metadata";

interface IVehicleImageProps {
	car: Car;
	class?: string;
}

interface IVehicleImageState {
	src: string;
	class: string;
}

export default class VehicleImage extends Component<IVehicleImageProps, IVehicleImageState> {

	constructor(props) {
		super(props);

    const imgSrc = resolveCarImage(props.car);

		this.state = {
			src: imgSrc,
			class: props.class === undefined ? 'img-fluid' : props.class,
		};
	}


	onLoadImgError = () => {
		this.setState({
			src: '/img/models/unknown.png',
		});
	};


	render = () => {
		return (
			<img className={this.state.class} src={this.state.src} alt="vehicle" onError={this.onLoadImgError}  />
		);
	};

}
