import React from 'react';
import {RentReservation, SettingExternalCarRental} from "autodevelo-api-client-ts";
import { Container, Row, Col } from 'react-bootstrap';
import VehiclePreview from "../../components/VehiclePreview/VehiclePreview";
import styles from '../../components/VehicleListItem/VehicleListItem.module.scss';
// import { Link } from 'react-router-dom';
import VehicleParameters from "../../components/VehicleParameters/VehicleParameters";
import {PriceHelper} from "../../utils/PriceHelper";

interface IReservationGeneralProps {
	reservation: RentReservation,
	settingExternalCarRental: SettingExternalCarRental;
}

function ReservationGeneral(props: IReservationGeneralProps) {
	const {reservation: { car }, settingExternalCarRental } = props;

	return (
		<section className="my-5">
			<Container>
				<Row className="align-items-center justify-content-center">
					<Col md={6} lg={4} xl={4}>
						<VehiclePreview car={car}/>
					</Col>
					<Col sm={8} lg={5} xl={5} className="col-xxl-6">
						<VehicleParameters
							car={car}
							settingExternalCarRental={settingExternalCarRental}
						/>
					</Col>
					<Col>
						<Row>
							<Col sm={12} className="text-center my-3">
								<div className={styles.priceHeadign}>Cena za {props.reservation.daysTotal} dní nájmu:</div>
								<div className={styles.price}>{PriceHelper.formatPrice(props.reservation.getTotalPriceTax())} vč. DPH</div>
							</Col>

							{!!props.reservation.getDepositTax() &&
								<Col sm={12} className="text-center my-3">
									<div className={styles.priceHeadign}>Záloha:</div>
									<div className={styles.price}>{PriceHelper.formatPrice(props.reservation.getDepositTax())} vč. DPH</div>
								</Col>
							}

							{!!props.reservation.getBailTax() &&
								<Col sm={12} className="text-center my-3">
									<div className={styles.priceHeadign}>Kauce:</div>
									<div className={styles.price}>{PriceHelper.formatPrice(props.reservation.getBailTax())} vč. DPH</div>
								</Col>
							}
						</Row>
					</Col>
				</Row>
			</Container>
		</section>
	);
}

export default ReservationGeneral;


