import React from 'react';
import {Col, Row} from 'react-bootstrap';

interface ICheckboxGroupProps {
	value: any,
	onChange: any,
	id: any,
	onBlur: any,
	children: any,
	touched: any,
	label: string
	align?: 'justify-content-left' | 'justify-content-center' | undefined;
	textAlign?: 'text-center' | 'text-left' | undefined;
}

export default class CheckboxGroup extends React.Component<ICheckboxGroupProps> {

  handleChange = event => {
    const target = event.currentTarget;
    let valueArray = [...this.props.value] || [];

    if (target.checked) {
      valueArray.push(target.id);
    } else {
      valueArray.splice(valueArray.indexOf(target.id), 1);
    }

    this.props.onChange(this.props.id, valueArray);
  };

  handleBlur = () => {
    this.props.onBlur(this.props.id, true);
  };

  render() {
    const { value, children, label, align, textAlign } = this.props;

    return (
			<Col sm="12">
				<Row className={align ? align : 'justify-content-center'}>
					<Col sm="12" className={"mb-4 " + (textAlign ? textAlign : 'text-center')}>
						<div className="form-label">{label}</div>
					</Col>
						{React.Children.map(children, child => {
							return React.cloneElement(child, {
								field: {
									value: value.includes(child.props.id),
									onChange: this.handleChange,
									onBlur: this.handleBlur
								}
							});
						})}
				</Row>
			</Col>
    );
  }
}
