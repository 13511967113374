export function writeHash(data: any, setURL: boolean = true) {
	const hash = btoa(JSON.stringify(data));
	if (setURL) {
		window.location.hash = hash;
	}
	return hash;
}

export function readHash() {
	try {
		const hash = window.location.hash;
		if (hash.length > 1) {
			return JSON.parse(atob(hash.slice(1)))
		}
	}
	catch (e) {
		console.log(e);
		return null
	}
}
